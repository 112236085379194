import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keepTheme } from "./Components/Common/theme";
import AxiosInterceptor from "./features/AxiosInterceptor";
import Routes from "./Route";
import './theme.css';
import { setTheme } from "./theme/actions";
import { getItemFromLocalStorage } from "./utils/localStorage";
import { registerModal } from "./Components/Login/actions";
function App(props) {
  const dispatch = useDispatch();
  const activity_tracking_api_url = useSelector((state) => state.config.activity_tracking_api_url);
  const api_url = useSelector((state) => state.config.api_url);
  const token = getItemFromLocalStorage("Token")

  useEffect(() => {
    localStorage.setItem('api_url', window.location.hostname === 'localhost' ? api_url : '');
    localStorage.setItem('activity_tracking_api_url', window.location.hostname === 'localhost' ? activity_tracking_api_url : '');

    if (getItemFromLocalStorage("Theme") === "light") {
      dispatch(setTheme("light"));
    } else {
      dispatch(setTheme("dark"));
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    keepTheme();
  });

  useEffect(() => {
    const pathname = window.location.search
    const url = pathname.includes("signup")
    if (url && !token) {
      dispatch(registerModal(true))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <AxiosInterceptor>
        <Routes />
      </AxiosInterceptor>
    </>
  );
}

export default App;
