import axios from "axios";

class HeaderApi {
  getClientBalance = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetClientBalance",
      data
    );
  getGames = (data) =>
    axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetGames", data);
}

const api = new HeaderApi();
export default api;
