import { Images } from "../../../Images";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import NavbarSearch from "./NavbarSearch";
import GameCard from "../../Game/GameCard";
import { useSelector } from "react-redux";
import NewTicket from "../../Models/Mail/NewTicket";
// import SwitchTheme from "./SwitchTheme";
import NavbarLang from "./NavbarLang";
import NavbarTime from "./NavbarTime";
import { replaceGamesKeysNames } from "../../../utils/helper";
import { sidebarRoutes } from "../../../RivieraBetLayout/Constant";
import { useTranslation } from "react-i18next";
import routes from "../../../Route/URLs";

export default function NavbarMainSidebar(props) {
  const { t } = useTranslation();
  const { closeHandler, isSidebar } = props;
  const [activeSearch, setActiveSearch] = useState(false);
  const [mailmodel, setmailmodel] = useState(false);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  const gamesData = useSelector(
    (state) => state?.headerReducer?.searchbarGames
  );

  function inputClick() {
    setActiveSearch(true);
  }

  let allGames = gamesData?.Games;
  const gameElements = allGames
    ? allGames.map((game) => {
        let result = replaceGamesKeysNames(game);
        return <GameCard key={result.name} {...result} navbarcard={true} closeHandler={closeHandler}/>;
      })
    : [];

	const fastLinks = [
		{ keyName: "Casino", path: routes.CASINO },
		{ keyName: "Live Casino", path: routes.LIVE_CASINO },
		{ keyName: "Games", path: routes.GAMES },
	];

  const sidebarRoutesElements = sidebarRoutes.map((link, index) => {
    return (
      <NavLink
        key={`${link.key}-tablet`}
        to={link.url}
        exact
        activeClassName="active" 
        className="sidebar-link sidebarM-link"
      >
        <div className="d-flex align-items-center">
          <div className="sidebar-link-icon">
            <img src={link.icon} alt="icon" />
          </div>
          <span>{t(link.name)}</span>
        </div>
        <img
          width="16"
          height="16"
          src={Images.chevronRightIcon}
          alt="chevron right icon"
        />
      </NavLink>
    );
  });

  return (
    <div
      className={`${
        isSidebar ? "active" : ""
      } sidebarM sidebarM-main on-tablet`}
    >
      <div className="sidebar-between">
        <div>
          <div className="sidebarM-header align-center">
            <div className="navbar-logo-t">
              <img
                className={themeMode === "light" ? "black-image" : ""}
                width="165"
                height="60"
                src={Images.headerLogo}
                alt="RivieraBet Logo"
              />
            </div>
            <div className="sidebarM-header-switch">
              {/* <div className="on-mobile">
                <SwitchTheme />
              </div> */}
              <button onClick={closeHandler} className="sidebarM-close">
                <img
                  className={themeMode === "light" ? "light-theme-icon" : ""}
                  width="17"
                  height="17"
                  src={Images.closeIcon}
                  alt="close icon"
                />
              </button>
            </div>
          </div>
          <div className="sidebarM-search">
            <NavbarSearch
              isTablet={true}
              inputTabletClickHandler={inputClick}
            />
            <NavLink exact activeClassName="active" to="/support" className="navbar-help">
              <img
                width="42"
                height="42"
                src={Images.supportIcon}
                alt="support icon"
              />
            </NavLink>
            <button className="navbar-email" onClick={() => setmailmodel(true)}>
              <img
                className={themeMode === "light" ? "light-theme-icon" : ""}
                width="25"
                height="20"
                src={Images.emailModelIcon}
                alt="email model icon"
              />
            </button>
          </div>

          {activeSearch && (
            <div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSearch(false);
                }}
                className="sidebarM-back"
              >
                <img
                  className="chevron-left"
                  width="16"
                  height="16"
                  src={Images.chevronRightIcon}
                  alt="chevron icon"
                />
                Back
              </button>
              <p className="navbar-search-title">{t("Recommended")}</p>
              <div className="sidebarM-search-games">
                {gameElements.length > 0 ? gameElements : null}
              </div>
              <div className="sidebarM-search-divider"></div>
              <p className="navbar-search-title">{t("Fast links")}</p>
              <div className="navbar-search-links">
                {fastLinks.map((link) => {
                  return (
                    <NavLink
                      exact 
                      activeClassName="active" 
                      to={link.path}
                      key={`${link.name}-t`}
                      className="navbar-search-link"
                    >
                      <img
                        className={themeMode === "light" ? "black-image" : ""}
                        width="25"
                        height="14"
                        src={Images.fastLinkIcon}
                        alt="fast link icon"
                      />
                      {t(link.keyName)}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          )}

          {!activeSearch && (
            <div>
              <div className="sidebarM-links">
                {sidebarRoutesElements}
                {/* <NavLink key={promotionRoute.key} to={promotionRoute.url} className="sidebarM-link">
									<div className="d-flex align-items-center">
										<div className="sidebar-link-icon">
											<img src={promotionRoute.icon} alt="icon" />
										</div>
										<span>{promotionRoute.name}</span>
									</div>
									<img width="16" height="16" src={Images.chevronRightIcon} alt="chevron right icon" />
								</NavLink> */}
              </div>
              <div className="sidebarM-main-divider"></div>
              <div className="sidebarM-lang">
                <p>{t("Language")}</p>
                <NavbarLang isTablet={true} />
              </div>
            </div>
          )}
        </div>
        <NavbarTime />
      </div>

      <NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
    </div>
  );
}
