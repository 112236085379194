import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { getItemFromLocalStorage } from "../utils/localStorage";

function LoadData(data, fallback, lng) {
  const languagesArr = ["da", "de", "en", "fr", "es", "it", "nn", "ru", "zh", "pt"]; //ptBR
  const loadLang = getItemFromLocalStorage("i18nextLng");
  if (!languagesArr.includes(loadLang)) {
    localStorage.setItem("i18nextLng", "en");
  }
  i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: true,
      // fallbackLng: fallback === undefined ? "en" : fallback,
      fallbackLng: "en",
      lng: i18n.language || getItemFromLocalStorage("i18nextLng"),
      interpolation: {
        escapeValue: false,
      },
      resources: data,
    });
}
// function ChangeLanguage(value) {
// 	return i18n.changeLanguage(value)
// }
const ChangeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const Localisation = {
  LoadData,
  ChangeLanguage,
};
