import { ThemeActions } from "../types";

const initialState = {
  theme: "dark",
};

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ThemeActions.SET_THEME: {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case ThemeActions.SET_THEME_SUCCESS: {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case ThemeActions.SET_THEME_FAILURE: {
      return {
        ...state,
        theme: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ThemeReducer;
