import React from "react";
import { selfExculsion } from "../lib/constants";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";

const SelfExclusion = () => {
  return (
    <CommonFooterTermsAndConditions title={selfExculsion[0]?.title} data={selfExculsion} />
  );
};

export default SelfExclusion;
