import { useTranslation } from "react-i18next";

export default function WithdrawFormHeader({ clientTotalAccount, clientBookingAmount, currency }) {
  const { t } = useTranslation();
  return (
    <div className="withdrawForm-balances">
      <div className="withdrawForm-balance">
        <div className="withdrawForm-balance-row ">
          <p className="withdrawForm-balance-title">{t("Total Available")}</p>
          <p className="withdrawForm-balance-title">
            {clientTotalAccount}
            {currency}
          </p>
        </div>
      </div>
      <div className="withdrawForm-balance">
        <div className="withdrawForm-balance-row ">
          <p className="withdrawForm-balance-title">{t("Pending to Withdraw")}</p>
          <p className="withdrawForm-balance-title">
            {clientBookingAmount}
            {currency}
          </p>
        </div>
      </div>
    </div>
  );
}
