import { all, fork } from "redux-saga/effects";
import { loginSaga } from "../../Components/Login/saga";
import { gamesSaga } from "../../modules/Games/saga";

import { headerSaga } from "../../Components/Navbar/saga";
import { bonusSaga } from "../../modules/Casher/Bonuses/Services/saga";
import { profileSaga } from "../../modules/Casher/Profile/Services/saga";
import { casherSaga } from "../../modules/Casher/Services/saga";
import { HomeSaga } from "../../modules/Home/Services/saga";
import { InplaySaga } from "../../modules/Inplay/Services/saga";
import { liveCasinoSaga } from "../../modules/LiveCasino/saga";
import { sportsSaga } from "../../modules/Sports/saga";
import { themeSaga } from "../../theme/saga";
import { casinoSaga } from "../../modules/Casino/saga";
// Here, we register our watcher saga(s) and export as a single generator
// function (startForeman) as our root Saga.
export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(gamesSaga),
    fork(liveCasinoSaga),
    fork(headerSaga),
    fork(themeSaga),
    fork(casherSaga),
    fork(bonusSaga),
    fork(profileSaga),
    fork(sportsSaga),
    fork(InplaySaga),
    fork(HomeSaga),
    fork(casinoSaga),
  ]);
}
