import { Alert } from "antd";
import browser from "browser-detect";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { changePassword } from "../../../Services/actions";
import {Input} from "antd";
import { useTranslation } from "react-i18next";

const browserInfo = browser();

const RightSettings = (props) => {
  const { changePassword } = props;
  const [password, setpassword] = useState({
    oldPwd: "",
    NewPwd: "",
    ConfirmPwd: "",
  });
  const [CheckPassword, setCheckPassword] = useState(true);
  const { t } = useTranslation();
  const ResponseCode = useSelector(
    (state) => state?.cashierReducer?.password?.data?.ResponseCode
  );

  useEffect(() => {
    if (password.NewPwd === password.ConfirmPwd) {
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
    //eslint-disable-next-line
  }, [password.ConfirmPwd]);

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const HandleChangePassword = async () => {
    if (!CheckPassword) {
      if (Token) {
        let payload = {
          Loader: true,
          PartnerId: PartnerId,
          TimeZone: 0,
          LanguageId: LanguageId,
          ProductId: null,
          Method: "ChangeClientPassword",
          Controller: "Client",
          CategoryId: null,
          PageIndex: 0,
          PageSize: 100,
          ProviderIds: [],
          Index: null,
          ActivationKey: null,
          MobileNumber: null,
          Code: null,
          RequestData: JSON.stringify({
            ClientId: ClientId,
            OldPassword: password.oldPwd,
            PartnerId: PartnerId,
            NewPassword: password.NewPwd,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await changePassword(payload);
        let deviceIp = await getDeviceIP();
        saveActivity({
          EventPage: "Cashier",
          EventType: "Profile_ChangePwd",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: "",
          attribute3: "",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
      setpassword({
        oldPwd: "",
        NewPwd: "",
        ConfirmPwd: "",
      });
    }
  };

  return (
    <div className="cabinetForm cabinet-block">
			<div className="cabinetForm-block">
				<div className="cabinetForm-title">{t("Change-password")}</div>
				<form action="" autoComplete="off">
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Old-password")}</p>
						<Input
							type="password"
              data-e2e={t("Old-password")}
							className="theme-input no-border"
							placeholder={t("Old-password")}
							value={password.oldPwd}
							onChange={(e) =>
								setpassword({ ...password, oldPwd: e.target.value })
							}
						/>
					</div>
					<div className="inputGroup">
						<p className="inputGroup-label">{t("New-password")}</p>
						<Input
							type="password"
              data-e2e={t("New-password")}
							className="theme-input no-border"
							placeholder={t("New-password")}
							value={password.NewPwd}
							onChange={(e) =>
								setpassword({ ...password, NewPwd: e.target.value })
							}
						/>
					</div>
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Confirm-password")}</p>
						<Input
							type="password"
							className="theme-input no-border"
							placeholder={t("Confirm-password")}
              data-e2e={t("Confirm-password")}
							value={password.ConfirmPwd}
							onChange={(e) =>
								setpassword({ ...password, ConfirmPwd: e.target.value })
							}
						/>
						{CheckPassword ? (
							<Alert
								message={t("PasswordMatchTo")}
								type="error"
								// style={{ margin: "10px 0px" }}
								showIcon
							/>
						) : null}
						{ResponseCode && ResponseCode !== 0 ? (
							<Alert
								message={t("InvalidOldPassword")}
								type="error"
								className="p-0"
								style={{ margin: "10px 0px" }}
								showIcon
							/>
						) : null}
					</div>
					<p className="cabinet-text">* {t("Password_Validation")}</p>
					<div data-e2e={t("Change-password")} className="cabinetForm-btn no-mobile theme-btn full-width sqr-btn" onClick={HandleChangePassword}>
						{t("Change-password")}
					</div>
				</form>
			</div>
    </div>
  );
};

export default connect(null, {
  changePassword,
})(RightSettings);
