import { Layout } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";
import "../assets/css/custom.scss";
import FooterComponent from "../Components/Footer/Footer";
import HeaderComponent from "../Components/Navbar/Navbar";
import { Images } from "../Images";
// import { getItemFromLocalStorage } from "../utils/localStorage";
import { SidebarMenuItemsWithRouter } from "./SidebarMenuWithRouter";
import LayoutComponentStyles from "./style";
import "./style.scss";
import { Helmet } from "react-helmet";
const { Content } = Layout;

class OPLayout extends React.Component {
	
  state = {
    // collapsed: false,
    // contentMargin: 253,
  };


  renderPageHeader = () => {
    const { history } = this.props; //?undefined kris
    return <HeaderComponent history={history} />;
  };

  renderContent = () => {
		const HomeBackground = {
      backgroundImage: `url(${Images.textureBackground})`,
			backgroundSize: "100px 100px",
			backgroundRepeat: "repeat"
    };
    const { children } = this.props;
    return (
      <>
        <div id="main-content" style={HomeBackground}>
          <div>
					<Content>{children}</Content>
					</div>
        </div>
      </>
    );
  };

  render() {
		
    return (
      <>
      <Helmet>
        <title>{`rivieraBet.com ${this?.props?.pageTitle ? `| ${this?.props?.pageTitle}` : ""}`}</title>
        <meta name="description" content="Join today for your chance to win real money"/>
      </Helmet>
        <Layout style={LayoutComponentStyles.parentLayout} className="layout">
          <SidebarMenuItemsWithRouter />
          <div className="layout-content">
						{this.renderPageHeader()}
						{this.renderContent()}
            <FooterComponent />
          </div>
        </Layout>
      </>
    );
  }
}

export default OPLayout;
