import BasicLayout from "../../../RivieraBetBasicLayout";

const CommonFooterTermsAndConditions = ({ title, data }) => {
  return (
    <BasicLayout>
      <div className="content-px content-py uw-content legal">
				<div className="layout-title">{title}</div>
				<div className="legal-block">
					{data?.map(({ expl }, i) => {
						return (
							<div key={expl}>
								{expl.map((e, j) => (
									<p key={j}>
										{e}
									</p>
								))}
							</div>
						);
					})}
				</div>
			</div>
    </BasicLayout>
  );
};

export default CommonFooterTermsAndConditions;
