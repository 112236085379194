import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Images } from "../../../Images";

const Nav = ({
  navEl,
  url,
  setUrl,
  selectedCurrency,
	isSingleLink
}) => {
  const { info } = Images;
  let ClientBalance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data
  );
  const { t } = useTranslation();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);


  let unused = ClientBalance?.Balances
    ? ClientBalance?.Balances[0]?.Balance
    : 0;
  let used = ClientBalance?.Balances ? ClientBalance?.Balances[1]?.Balance : 0;
  let clientBooking = 0;
  return (
    <>
      <div className="cabinet-subNav cabinet-subNav-components">
        <div className="cabinet-subNav-links">
          {navEl.map((el, i) => (
            <div data-e2e={t(el)} key={el} className={`${url === el || isSingleLink ? 'active' : ''} cabinet-subNav-link`} onClick={() => {setUrl(el);}}>
              {t(el)}
            </div>
          ))}
        </div>
        <div className="cabinet-subNav-info">
          <Tooltip
            placement="bottomRight"
            title={
              <div className="info-tooltip-text">
                <div>
                  {t("Client Unused Balance")}: {unused} {selectedCurrency}
                </div>
                <hr className="info-tooltip" />
                <div data-e2e={`client-used-balance${used}`}>
                  {t("Client Used Balance")}: {used} {selectedCurrency}
                </div>
                <hr className="info-tooltip" />
                <div>
                  {t("Client Booking")}: {clientBooking} {selectedCurrency}
                </div>
              </div>
            }
          >
            <div className="cabinet-subNav-info-content">
							<img className={themeMode === 'light' ? 'black-image' : ''} width="14" height="14" loading="lazy" src={info} alt="info icon" />
							<div className="cabinet-text">{t("User info")}</div>
						</div>
          </Tooltip>
        </div>
      </div>
			<div className="cabinet-divider"></div>
    </>
  );
};

export default Nav;
