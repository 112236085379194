import { HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { Images } from "../../Images";
import Banner from "../Home/components/Banner";
import { useSelector } from "react-redux";
import MobileNavbar from "../../Components/MobileNavbar/MobileNavbar";
import { useTranslation } from "react-i18next";

const CasinoHeader = ({ searchText, setSearchText }) => {
  const { t } = useTranslation();

  const [connection, setConnection] = useState(null);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [win, setWin] = useState();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(
        "https://websitewebapi.oceanbet.io/basehub?PartnerId=43&Token=&LanguageId=en&TimeZone=0"
      )
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {})
        .catch((error) => console.log(error));
      connection.on("onWin", (data) => {
        setWin(data);
      });
    }
  }, [connection]);

  useEffect(() => {
    if (win !== undefined) {
      setdata([...data, win]);
    }
    //eslint-disable-next-line
  }, [win]);

  return (
    <div className="casino-header">
      <div className="content-px banner-px">
        <MobileNavbar />
        <div className="casino-header-container">
          <Banner hideContent={true} />
          <div className={`${open ? "opened" : ""} casino-win`}>
            <button
              className="casino-win-header"
              onClick={() => setOpen((prev) => !prev)}
            >
              <p className="casino-win-heading">{t("Top wins")}</p>
              <div className={`${open ? "opened" : ""} casino-win-arrow`}>
                <img 
                  src={
                    themeMode === "dark"
                      ? Images.chevronYellow
                      : Images.chevronYellowLight
                  }
                  width="17"
                  height="17"
                  loading="lazy"
                  alt="prev icon"
                />
              </div>
            </button>
            <div className={`${open ? "opened" : ""} casino-win-block`}>
              <br />
							<div className="casino-win-scroll casino-scroll">
                {data && data.length > 0 ? (
                  data.map((e, i) => {
                    return (
                      <div className="casino-win-data" key={e.Amount}>
                        <div className="casino-win-info">
                          <img src={e.ImageUrl} alt={e.GameName} />
                          <p>{e.GameName}</p>
                        </div>
                        <div className="casino-win-value">
                          <p className="casino-win-id">ID {e.ProductId}</p>
                          <p className="casino-win-currency">
                            {e.CurrencyId} {e.Amount}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="no-results t-center">{t("Data not found")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoHeader;
