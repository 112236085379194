import { liveCasinoActions } from "../types";

const initialState = {
  getProductURLData: [],
  getProductURL: false,
};

const LiveCasinoReducer = (state = initialState, action) => {
  switch (action.type) {
    case liveCasinoActions.GET_PRODUCT_URL: {
      return {
        ...state,
        getProductURL: false,
        getProductURLData: action.payload,
      };
    }
    case liveCasinoActions.GET_PRODUCT_URL_SUCCESS: {
      return {
        ...state,
        getProductURL: true,
        getProductURLData: action.payload,
      };
    }
    case liveCasinoActions.GET_PRODUCT_URL_ERROR: {
      return {
        ...state,
        getProductURL: false,
        getProductURLData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LiveCasinoReducer;
