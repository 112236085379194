import { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getItemFromLocalStorage } from "../../../utils/localStorage";

const CasherNav = createContext();

export const CasherProvider = ({ children }) => {
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  const [navURL, setNavURL] = useState("Wallets");
  const [finUrl, setFinUrl] = useState("Deposit");
  const [histUrl, setHistUrl] = useState("Bets");
  const [bonusUrls, setBonusUrl] = useState("Bonuses");
  const [ticketUrl, setTicketUrl] = useState("My_tickets");
  const [profileUrl, setProfileUrl] = useState("Settings");
  const [balanceUrl, setBalanceUrl] = useState("Balance");
  const [partnerPayment, setPartnerPayment] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyId);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);

  const location = window.location.pathname;
  const ClientId = getItemFromLocalStorage("Id");
  const PartnerId = getItemFromLocalStorage("PartnerId");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetPartnerPaymentSystems", {
      PartnerId: PartnerId,
      ClientId: ClientId,
      LanguageId: getItemFromLocalStorage("i18nextLng") === "en-GB" ? "en" : getItemFromLocalStorage("i18nextLng"),
      TimeZone: 0,
    });
    if (res) {
      setLoading(false);
    }
    setPartnerPayment(res?.data?.PartnerPaymentSystems);
  };

  useEffect(() => {
    if (ClientId) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [ClientId]);

  useEffect(() => {
    setNavURL(location?.split("/")[3]?.charAt(0)?.toUpperCase() + location.split("/")[3]?.slice(1));
  }, [location]);

  return (
    <CasherNav.Provider
      value={{
        navURL,
        loading,
        setNavURL,
        finUrl,
        setFinUrl,
        histUrl,
        setHistUrl,
        bonusUrls,
        setBonusUrl,
        ticketUrl,
        setTicketUrl,
        profileUrl,
        setProfileUrl,
        balanceUrl,
        setBalanceUrl,
        partnerPayment,
        setPartnerPayment,
        selectedCurrency,
        setSelectedCurrency,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
      }}>
      {children}
    </CasherNav.Provider>
  );
};

export default CasherNav;
