export const UpdateProfileActions = {
  UPDATE_PROFILE_INFO: "UPDATE_PROFILE_INFO",
  UPDATE_PROFILE_INFO_SUCCESS: "UPDATE_PROFILE_INFO_SUCCESS",
  UPDATE_PROFILE_INFO_ERROR: "UPDATE_PROFILE_INFO_ERROR",
  SEND_VERIFICATION_CODE: "SEND_VERIFICATION_CODE",
  SEND_VERIFICATION_CODE_SUCCESS: "SEND_VERIFICATION_CODE_SUCCESS",
  SEND_VERIFICATION_CODE_ERROR: "SEND_VERIFICATION_CODE_ERROR",
  DOCUMENT_TYPE: "DOCUMENT_TYPE",
  DOCUMENT_TYPE_SUCCESS: "DOCUMENT_TYPE_SUCCESS",
  DOCUMENT_TYPE_ERROR: "DOCUMENT_TYPE_ERROR",
  SUBMIT_DOCUMENT: "SUBMIT_DOCUMENT",
  DOCUMENT_HISTORY: "DOCUMENT_HISTORY",
  DOCUMENT_HISTORY_SUCCESS: "DOCUMENT_HISTORY_SUCCESS",
  DOCUMENT_HISTORY_ERROR: "DOCUMENT_HISTORY_ERROR",
  DOCUMENT_STATUS: "DOCUMENT_STATUS",
  DOCUMENT_STATUS_SUCCESS: "DOCUMENT_STATUS_SUCCESS",
  DOCUMENT_STATUS_ERROR: "DOCUMENT_STATUS_ERROR",
};
