import PaymentCard from "./PaymentCard";
import { useContext, useEffect, useState } from "react";
import CasherNav from "../../context/context";
import { getItemFromLocalStorage } from "../../../../utils/localStorage";

const DepositPayMethods = () => {
  const [cardSelected, setCardSelected] = useState(0);
  const [methods, setMethods] = useState([]);
  const { partnerPayment, setSelectedPaymentMethod } = useContext(CasherNav);

  useEffect(() => {
    const CurrencyId = getItemFromLocalStorage("CurrencyId");
    let options = [];
    if (partnerPayment.length > 0) {
      partnerPayment.forEach((p) => {
        if (p.CurrencyId === CurrencyId && p.Type === 2) {
          options.push(p);
          console.log(p);
        }
      });
    }

    setMethods(options);
    setSelectedPaymentMethod(options[0]);
    // eslint-disable-next-line
  }, [partnerPayment]);

  return (
    <div className="deposit-scroll">
      <div className="deposit-grid">
        {methods?.map((card, i) => (
          <PaymentCard
            key={i}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
            i={i}
            card={card}
          />
        ))}
      </div>
    </div>
  );
};

export default DepositPayMethods;
