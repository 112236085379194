import { BonusActions } from "../types";

export const bonusList = (payload) => {
  return { type: BonusActions.GET_BONUS_STATUS_LIST, payload: payload };
};

export const bonusStatusListSuccess = (payload) => {
  return { type: BonusActions.GET_BONUS_STATUS_LIST_SUCCESS, payload: payload };
};

export const bonusStatusListError = (payload) => {
  return {
    type: BonusActions.GET_BONUS__STATUS_LIST_ERROR,
    payload: payload,
  };
};

export const bonuses = (payload) => {
  return { type: BonusActions.GET_BONUSES, payload: payload };
};

export const bousesSuccess = (payload) => {
  return { type: BonusActions.GET_BONUSES_SUCCESS, payload: payload };
};

export const bonusesError = (payload) => {
  return {
    type: BonusActions.GET_BONUSES_ERROR,
    payload: payload,
  };
};


export const handlepromocode = (payload) => {
  return { type: BonusActions.GET_PROMOCODE, payload: payload };
};

export const promocodeSuccess = (payload) => {
  return { type: BonusActions.GET_PROMOCODE_SUCCESS, payload: payload };
};

export const promocodeError = (payload) => {
  return {
    type: BonusActions.GET_PROMOCODE_ERROR,
    payload: payload,
  };
};

export const getInfo = (payload) => {
  return { type: BonusActions.GET_INFO, payload: payload };
};

export const infoSuccess = (payload) => {
  return { type: BonusActions.GET_INFO_SUCCESS, payload: payload };
};

export const infoError = (payload) => {
  return {
    type: BonusActions.GET_INFO_ERROR,
    payload: payload,
  };
};