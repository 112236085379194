import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const BonusesForms = ({
  setStatusSelected,
  bonusStatusList,
  setBonus,
  promocode,
  setPromocode,
  bonus,
  getBounses,
  handlePromocode
}) => {
  const { t } = useTranslation();


  return (
		<div className="cabinet-subNav cabinet-subNav-promo">
			<div className="bonus-promo">
				<div className="bonus-promo-btns">
					<button type="button" className={`${bonus===1 ? "active" : ""} cabinet-text bonus-promo-btn`} onClick={()=>setBonus(1)}>{t("Common")}</button>
					<button type="button" onClick={()=>setBonus(6)} className={`${bonus===6 ? "active" : ""} cabinet-text bonus-promo-btn`}>{t("Sportsbook")}</button>
				</div>
				<div className="bonus-promo-row">
					<div className="cabinet-blockBtn">
						<Input
							placeholder={t("PromoCode-placeholder")}
							name=""
							value={promocode}
							className="theme-input no-border bonus-promo-input"
							onChange={(e)=>setPromocode(e.target.value)}
						/>
						<button className={`cabinet-btn ${!promocode&&"cursor-disable"}`} disabled={!promocode} onClick={handlePromocode}>
							{t("ActivateBonus")}
						</button>
					</div>
				</div>
			</div>
			<div className="bonus-promo-show">
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Status")}</p>
					<Select
						className="theme-input"
						placeholder={t("Select Status")}
						labelInValue
						onChange={(e) =>
							setStatusSelected(e.value)
						}
					>
						{bonusStatusList &&
							bonusStatusList?.map((e) => {
								return (
									<Option className="opt" value={e?.Id} key={e?.Id}>
										{t(e?.Name)}
									</Option>
								);
							})}
					</Select>
				</div>
				<button onClick={getBounses} className="cabinet-btn">{t("Show")}</button>
			</div>
		</div>
  );
};

export default BonusesForms;
