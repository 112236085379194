import { call, put, takeLatest } from "redux-saga/effects";
import { getError, openNotificationWithIcon } from "../../../utils/helper";
import { addItemToLocalStorage, clearAllFromLocalStorage, getItemFromLocalStorage, removeItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import HeaderApi from "../../Navbar/api";
import { headeractions } from "../../Navbar/types";
import loginApi from "../api";
import { LoginActions } from "../types";

function* startSession(action) {
  try {
    const response = yield call(loginApi.loginUser, { Data: action.payload });
    if (response?.data && response?.data?.ResponseCode === 0) {
      const { Id, Email, CurrencyId, FirstName, LastName, LanguageId, Token, UserName } = response?.data;

      const clientData = yield call(loginApi.getClientByToken, {
        LanguageId: LanguageId,
        Token: Token,
      });

      addItemToLocalStorage("Id", Id);
      addItemToLocalStorage("Email", Email);
      addItemToLocalStorage("CurrencyId", CurrencyId);
      addItemToLocalStorage("FirstName", FirstName);
      addItemToLocalStorage("LastName", LastName);
      addItemToLocalStorage("LanguageId", LanguageId);
      addItemToLocalStorage("UserName", UserName);
      addItemToLocalStorage("Token", Token);
      addItemToLocalStorage("userData", clientData?.data);
      saveActivity({
        EventPage: "Login",
        EventType: "LoginSuccess",
        attribute1: UserName || "",
      });
      yield put({
        type: LoginActions.GET_CLIENT_BY_TOKEN_SUCCESS,
        payload: clientData,
      });
      if (clientData?.data && clientData?.data?.ResponseCode === 0) {
        const { PartnerId, Id, Token, CurrencyId } = clientData?.data;
        addItemToLocalStorage("PartnerId", PartnerId);
        let clientBalance = yield call(HeaderApi.getClientBalance, {
          Token: Token,
          ClientId: Id,
          PartnerId: PartnerId,
          RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
        });
        yield put({
          type: headeractions.GET_CLIENT_BALANCE_SUCCESS,
          payload: clientBalance,
        });
      }
    }
    if (response?.data?.ResponseCode !== 0) {
      saveActivity({
        EventPage: "Login",
        EventType: "LoginFail",
        attribute1: "",
      });
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({ type: LoginActions.LOGIN_SUCCESS, payload: response });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.LOGIN_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* userSignup(action) {
  try {
    const response = yield call(loginApi.userSignup, { Data: action.payload });
    if (response?.data && response?.data?.ResponseCode === 0) {
      removeItemFromLocalStorage("step1");
      removeItemFromLocalStorage("step2");
      removeItemFromLocalStorage("step3");
      removeItemFromLocalStorage("searchParams");
      yield put({ type: LoginActions.SIGNUP_SUCCESS, payload: response });
      return true;
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
      yield put({ type: LoginActions.SIGNUP_ERROR, payload: response });
    }
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.SIGNUP_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getCities(action) {
  try {
    const response = yield call(loginApi.cities, { Data: action.payload });
    if (response?.data && response?.data?.ResponseCode === 0) {
      yield put({ type: LoginActions.GET_CITIES_SUCCESS, payload: response });
      return true;
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.GET_CITIES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getCountries(action) {
  try {
    const response = yield call(loginApi.countries, { Data: action.payload });
    if (response?.data && response?.data?.ResponseCode === 0) {
      yield put({
        type: LoginActions.GET_COUNTRIES_SUCCESS,
        payload: response,
      });
      return true;
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.GET_COUNTRIES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getClientByToken(action) {
  try {
    const response = yield call(loginApi.getClientByToken, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
      const { PartnerId } = response?.data;
      addItemToLocalStorage("PartnerId", PartnerId);
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: LoginActions.GET_CLIENT_BY_TOKEN_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getClientByToken saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.GET_CLIENT_BY_TOKEN_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}
function* loginModal(action) {
  try {
    yield put({
      type: LoginActions.LOGIN_MODAL_SUCCESS,
      payload: action.payload,
    });
    return true;
  } catch (error) {
    console.error("error in loginModal saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.LOGIN_ERROR, payload: customizeError });
    return false;
  }
}

function* registerModalFlag(action) {
  try {
    yield put({
      type: LoginActions.REGISTER_MODAL_SUCCESS,
      payload: action.payload,
    });
    return true;
  } catch (error) {
    console.error("error in registerModal saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: LoginActions.REGISTER_MODAL_ERROR,
      payload: customizeError,
    });
    return false;
  }
}
const logout = () => {
  if (LoginActions.LOGOUT) {
    const Theme = getItemFromLocalStorage("Theme");
    const i18nextLng = getItemFromLocalStorage("i18nextLng");
    clearAllFromLocalStorage();
    addItemToLocalStorage("Theme", Theme);
    addItemToLocalStorage("i18nextLng", i18nextLng);
  }
};

function* passwordForgot(action) {
  try {
    const response = yield call(loginApi.recoveryPassword, {
      Data: action.payload,
    });
    if (response?.data && response?.data?.ResponseCode === 0) {
      yield put({
        type: LoginActions.FORGOT_PASSWORD_SUCCESS,
        payload: response,
      });
      return true;
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: LoginActions.FORGOT_PASSWORD_FAILURE, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* loginSaga() {
  yield takeLatest(LoginActions.LOGIN, startSession);
  yield takeLatest(LoginActions.SIGNUP, userSignup);
  yield takeLatest(LoginActions.GET_CITIES, getCities);
  yield takeLatest(LoginActions.GET_COUNTRIES, getCountries);
  yield takeLatest(LoginActions.GET_CLIENT_BY_TOKEN, getClientByToken);
  yield takeLatest(LoginActions.LOGIN_MODAL, loginModal);
  yield takeLatest(LoginActions.REGISTER_MODAL, registerModalFlag);
  yield takeLatest(LoginActions.LOGOUT, logout);
  yield takeLatest(LoginActions.FORGOT_PASSWORD, passwordForgot);
}
