import { Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import browser from "browser-detect";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  generateNewTicket,
  getTicketHistory,
} from "../../../modules/Casher/Services/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../Common/getIp";
import { Images } from "../../../Images";
import { useTranslation } from "react-i18next";
const browserInfo = browser();

const NewTicket = (props) => {
  const { t } = useTranslation();

  const { generateNewTicket, getTicketHistory } = props;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");

  const onFinish = async (data) => {
    const { subject, ticket } = data;
    let payload = {
      Loader: true,
      Controller: "Client",
      PartnerId: PartnerId,
      Method: "OpenTicket",
      TimeZone: 0,
      RequestData: JSON.stringify({
        Subject: subject,
        Message: ticket,
      }),
      ClientId: ClientId,
      Token: Token,
    };
    await generateNewTicket(payload);
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Ticket_NewTicket",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
    props.setmailmodel(false);
    setTimeout(() => {
      TicketHistory();
    }, 2000);
  };

  const TicketHistory = async () => {
    if (Token) {
      let payload = {
        Loader: true,
        Controller: "Client",
        PartnerId: PartnerId,
        Method: "GetClientTickets",
        TimeZone: 0,
        RequestData: JSON.stringify({
          SkipCount: 0,
          TakeCount: 10,
        }),
        ClientId: ClientId,
        Token: Token,
      };
      await getTicketHistory(payload);
    }
  };

  useEffect(() => {
    TicketHistory();
    //eslint-disable-next-line
  }, []);

  function handleClose(e) {
    e.stopPropagation();
    props.setmailmodel(false);
  }

  return (
    <Modal
      open={props.mailmodel}
      footer={null}
      width="700px"
      destroyOnClose
      className="theme-modal mailModal"
      onCancel={(e) => handleClose(e)}
    >
      <div className="theme-modal-header mailModal-header">
        <p className="theme-modal-heading">{t("New ticket")}</p>
        <button
          type="button"
          onClick={(e) => handleClose(e)}
          className={`theme-modal-close ${
            themeMode === "light" ? "light-theme" : ""
          }`}
        >
          <img
            loading="lazy"
            width="17"
            height="17"
            src={Images.closeIcon}
            alt="close icon"
          />
        </button>
      </div>
      <div className="theme-modal-body">
        <Form name="login" onFinish={onFinish} autoComplete="off">
          <div className="inputGroup">
            <p className="inputGroup-label">{t("Subject")}</p>
            <Form.Item
              name="subject"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: t("Please enter subject here"),
                },
              ]}
            >
              <Input
                placeholder={t("Text")}
                className="theme-input no-border"
              />
            </Form.Item>
          </div>
          <div className="inputGroup">
            <p className="inputGroup-label">{t("Ticket")}</p>
            <Form.Item
              name="ticket"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: t("Please enter your message here"),
                },
              ]}
            >
              <TextArea
                className="theme-input no-border"
                placeholder={t("Write your text")}
              />
            </Form.Item>
          </div>
          <button className="theme-btn full-width sqr-btn no-mobile">
            {t("Send")}
          </button>
        </Form>
      </div>
    </Modal>
  );
};

export default connect(null, {
  generateNewTicket,
  getTicketHistory,
})(NewTicket);
