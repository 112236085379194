import { Images } from "../../../../../Images";
import { contactSupport } from "../../../lib/constants";

const ContactSocial = () => {
  const { blackPhoneIcon, blackEmailIcon, blackAddressIcon } = Images;

  return (
    <div>
      <div className="support-contact">
        <div className="support-faq-header">
          <p>Contact details</p>
        </div>
        <div className="support-faq-divider"></div>
        <div className="support-faq-block">
          <p className="support-contact-title">Riviera bet</p>
          {contactSupport.map((cont, i) => {
            return (
              <div key={cont} className="support-contact-group">
                <div className="support-contact-group-icon">
                  <img
                    className=""
                    width="40"
                    height="40"
                    loading="lazy"
                    src={
                      cont.title === "Phone:"
                        ? blackPhoneIcon
                        : cont.title === "Email:"
                        ? blackEmailIcon
                        : blackAddressIcon
                    }
                    alt="contact icon"
                  />
                  <div className="support-contact-group-title">
                    {cont.title}
                  </div>
                </div>
                <a
                  href={`mailto:${cont.text}`}
                  className="support-contact-group-text"
                >
                  {cont.text}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactSocial;
