import axios from "axios";
import { Images } from "../../../Images";
import routes from "../../../Route/URLs";
import { getItemFromLocalStorage } from "../../../utils/localStorage";

const { finances, history, bonuses, profile, ticket, balance } = Images;

export const formsEl = {
  formElStatus: [{ label: "Status", option: ["Select Status", "Option2", "Option3"] }],
  formsElLimit: [{ label: "", option: ["Total Deposit"] }],
  formsElExcl: [{ label: "", option: ["Temporary"] }],
  formElPhone: [
    {
      img: Images.en,
      value: "+44",
    },
    {
      img: Images.fr,
      value: "+39",
    },
  ],
};

export const walletsNavEl = ["Deposit", "Withdraw", "Bank_Accounts"];

export const navEl = [
  {
    img: finances,
    imgLight: Images.financesLight,
    title: "Wallet",
    route: routes.CASHER_WALLETS,
  },
  {
    img: history,
    imgLight: Images.historyLight,
    title: "History",
    route: routes.CASHER_HISTORY,
  },
  {
    img: bonuses,
    imgLight: Images.bonusesLight,
    title: "Bonuses",
    route: routes.CASHER_BONUSES,
  },
  {
    img: profile,
    imgLight: Images.profileLight,
    title: "Profile",
    route: routes.CASHER_PROFILE,
  },
  {
    img: ticket,
    imgLight: Images.ticketLight,
    title: "Ticket",
    route: routes.CASHER_TICKET,
  },
  {
    img: balance,
    imgLight: Images.balanceLight,
    title: "Balance",
    route: routes.CASHER_BALANCE,
  },
];

export const historyNavEl = ["Bets", "Transactions", "Payments"];
export const bonusesNavEl = ["Bonuses"];
export const accountsEl = ["Id", "NickName", "User_BankName", "Iban", "Address"];
export const ticketNavEl = ["My_tickets"];
export const profileNavEl = ["Settings", "AccountVerification", "Responsible Gaming"];

export const balanceNavEl = ["Balance"];

export const verificationList = ["DocumentFormatMessage", "DocumentValidityPeriod", "DocumentValidityColor", "DocumentValidityOriginal", "DocumentValiditySignature", "DocumentValidityPresent"];

export const currencyId = (type, partnerPayment, formEl, setFormEl) => {
  const options = [];
  partnerPayment
    // eslint-disable-next-line
    .filter((pay) => {
      if (pay.Type === type) {
        return pay;
      }
    })
    // eslint-disable-next-line
    .map((pay) => {
      if (!options.includes(pay.CurrencyId)) {
        options.push(pay.CurrencyId);
      }
    });

  setFormEl({ ...formEl, option: options });
};

export const currencyToSymbol = (currency) => {
  if (currency === "AUD") return "A$";
  if (currency === "CAD") return "C$";
  if (currency === "EUR") return "€";
  if (currency === "GBP") return "£";
  if (currency === "USD") return "$";
  else return currency;
};

export const addPoint = (amount) => {
  if (amount / 1000 >= 1) {
    // eslint-disable-next-line
    return amount / 1000 + "," + "000";
  }
};

export const chooseImg = (name, sel, i) => {
  if (name === "Skrill") return Images.skrillWhite;
  if (name === "Neteller") return Images.netellerWhite;
  if (name === "NOWPay") return Images.PaymentNowpay;
  if (name === "PaymentIQCryptoPay") return Images.PaymentCrytopay;
  if (name === "PaymentIQLuxon") return Images.PaymentLuxon;
  if (name === "PaymentIQInterac") return Images.PaymentInterac;
  if (name === "OptimumWay") return Images.PaymentVisa;
  if (name === "NOWPayFiat") return Images.PaymentFiat;
  if (name === "Praxis") return Images.Praxis;
  if (name === "XcoinsPayCrypto") return Images.XcoinsPayCrypto;
  if (name === "XcoinsPayCard") return Images.XcoinsPayCard;

  // if (name?.includes("PaymentIQC")) return Images.cryptoWhite;

  // if (name?.includes("MoneyPayAmerican") && sel === i) {
  //   return american;
  // } else if (name.includes("MoneyPayAmerican")) return americanIn;

  // if (name?.includes("MoneyPayVisa") && sel === i) {
  //   return visaCa;
  // } else if (name?.includes("MoneyPayVisa")) return visaCaIn;
};

export const cardName = (PaymentSystemName) => {
  if (PaymentSystemName?.includes("PaymentIQ")) {
    return PaymentSystemName?.substring(9);
  } else if (PaymentSystemName?.includes("MoneyPayVisa")) {
    return PaymentSystemName?.substring(8, 12) + "/" + PaymentSystemName?.substring(12);
  } else if (PaymentSystemName?.includes("MoneyPayAmerican")) {
    return PaymentSystemName?.substring(8, 16) + " " + PaymentSystemName?.substring(16);
  } else if (PaymentSystemName === "Neteller" || PaymentSystemName === "Skrill") {
    return "Please contact your VIP Manager";
  } else {
    return PaymentSystemName;
  }
};

export const handleClientBalance = () => {
  const PartnerId = getItemFromLocalStorage("PartnerId");
  const Token = getItemFromLocalStorage("Token");
  const ClientId = getItemFromLocalStorage("Id");
  const payload = {
    ClientId: ClientId,
    Controller: "Client",
    Loader: true,
    Method: "GetClientAccounts",
    PartnerId: PartnerId,
    RequestData: JSON.stringify({ ClientId: ClientId }),
    Token: Token,
  };
  if (Token) {
    const response = axios.post("https://websitewebapi.oceanbet.io/43/api/Main/ApiRequest", payload);
    return response;
  }
};
