import { useTranslation } from "react-i18next";
import MainHistory from "../../History/components/MainHistory";

const AccountsTable = () => {
  const { t } = useTranslation();

  const navElAccounts = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("NickName"),
      dataIndex: "Nickname",
      key: "Nickname",
      render: (text) => <p>{text === 1 ? "Active" : "Closed"}</p>,
    },
    {
      title: t("User_BankName"),
      dataIndex: "Coin",
      key: "Coin",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Iban"),
      dataIndex: "Token",
      key: "Token",
      // render: (text) => <p>{moment(text).format("LL")}</p>,
      responsive: ["sm"],
    },
    {
      title: t("Address"),
      dataIndex: "Address",
      key: "Address",
      // render: (text) => <p>{moment(text).format("LL")}</p>,
    },
  ];
  return (
    <>
      <MainHistory
        empty={t("Banks-empty")}
        img={"account"}
        navEl={navElAccounts}
      />
    </>
  );
};

export default AccountsTable;
