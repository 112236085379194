export const getRoute = (route) => {
  // if(route === "/") return route
  // const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/riviera-bet/";
  // const fullRoute = BASE_ROUTE ? `${BASE_ROUTE}${route}` : route;
  return `/${route}`;
};

const routes = {
  ACCESS_DENIED: getRoute(`access-denied`),
  HOME_PAGE: "/",
  CASINO: getRoute(`casino`),
  LIVE_CASINO: getRoute(`live-casino`),
  LIVE_CASINO_PREVIEW: getRoute(`live-casino/preview`),
  GAMES: getRoute(`games`),
  SPORTS: getRoute(`sports`),
  INPLAY: getRoute(`inplay`),
  PROMOTIONS: getRoute(`promotions`),
  CASHER: getRoute(`cashier`),
  CASHER_WALLETS: getRoute(`cashier/wallets`),
  CASHER_HISTORY: getRoute(`cashier/history`),
  CASHER_BONUSES: getRoute(`cashier/bonuses`),
  CASHER_PROFILE: getRoute(`cashier/profile`),
  CASHER_TICKET: getRoute(`cashier/ticket`),
  CASHER_BALANCE: getRoute(`cashier/balance`),
  TERMS_CONDITION: getRoute(`terms&conditions`),
  SUPPORT: getRoute(`support`),
  SINGLE_PROMOTION: getRoute(`promotions/:id`),
  HELP: getRoute(`help`),
  ABOUT_US: getRoute(`about-us`),
  RESPONSIBLE_GAMING: getRoute(`responsible-gaming`),
  SELF_EXCLUSION: getRoute(`self-exclusion`),
  DISPUTE_RESOLUTION: getRoute(`dispute-resolution`),
  AMP_PAGE: getRoute(`aml-policy`),
  FAIRNESS_AND_RNG_TESTING: getRoute(`fairness-rng-testing`),
  PRIVACY_POLICY: getRoute(`privacy-policy`),
  ACCOUNTS_PAYOUTS_BONUS: getRoute(`accounts-payouts-bonus`),
  SPORTS_RULES: getRoute(`sportsbook-rules`),
  NO_ACCESS: '/no-access'
};
export default routes;
