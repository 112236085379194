import { Images } from "../../../Images";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

export default function GameNavbarSearch({ searchText, setSearchText }) {
    const { t } = useTranslation();
    return (
        <div className="casino-nav-search">
            <Input
                placeholder={t("Search_game")}
                value={searchText}
                className="theme-input"
                onChange={(e) => setSearchText(e.target.value)}
                prefix={
                    <img
                        width="18"
                        height="18"
                        src={Images.searchIcon}
                        alt="icon"
                        loading="lazy"
                    />
                }
            />
        </div>
    );
}
