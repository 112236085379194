import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { Images } from "../../../Images/index";

import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage
} from "../../../utils/localStorage";
import { getAllCountries } from "../../Login/actions";

const { Option } = Select;

const Step2 = ({ registerPayload, setRegisterPayload, setFormSteps }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  let allCountriesData = useSelector(
    (state) => state?.loginReducer?.allCountries?.data?.ResponseObject
  );
	console.log(allCountriesData)

  // let allCitiesData = useSelector(
  //   (state) => state?.loginReducer?.allCities?.data?.ResponseObject
  // );

  const onFinish = async (values) => {
    let obj = {
      Address: values.Address,
      MobileCode: values.MobileCode,
      MobileNumber: values.countrycode,
      Country: values.Country,
      City: "",
      District: "",
      DocumentNumber: "",
    };
    setRegisterPayload({ ...registerPayload, ...obj });
    addItemToLocalStorage("step2", obj);
    setFormSteps(3);
  };
  let payload = { LanguageId: "en", PartnerId: 43, TimeZone: 0 };

  // const handleCities = (value) => {
  //   dispatch(
  //     getAllCities({
  //       ...payload,
  //       ParentId: value,
  //       TypeId: 3,
  //     })
  //   );
  // };

  let step2Data = getItemFromLocalStorage("step2");

  useEffect(() => {
    dispatch(getAllCountries({ ...payload, TypeId: 5 })); // eslint-disable-next-line
  }, []);

  return (
    <>
			<Form
				name="register"
				initialValues={{
					Address: step2Data?.Address,
					countrycode: step2Data?.MobileCode,
					MobileNumber: step2Data?.MobileNumber,
					Country: step2Data?.Country,
					City: step2Data?.City,
					District: "",
					DocumentNumber: step2Data?.DocumentNumber || "",
				}}
				form={form}
				onFinish={onFinish}
				autoComplete="off"
			>
				<Form.Item name="Country" rules={[{required: true, message: t("Country-option")}]}>
					<Select
						className="theme-input suffix-select"
						showSearch data-e2e="country" placeholder={t("Country-option")} optionFilterProp="children"
						// onChange={handleCities}
						suffixIcon={<img width="18" height="18" loading="lazy" src={Images.countryIcon} alt="icon" />}
						filterOption={(input, option) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{allCountriesData?.map(({ Id, Name }) => (
							<Option value={Id} key={Id} data-e2e={Name}>{Name}</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="Address" rules={[{required: true, message: t("Please input your Address")}]}>
					<Input
						placeholder={t("Address-placeholder")}
						data-e2e="address"
						className="theme-input"
						prefix={<img width="18" height="18" src={Images.userIconModal} alt="icon" />}
					/>
				</Form.Item>
				<div className="authModal-phone">
					<Form.Item name="countrycode" rules={[{required: false, message: t("Please input your country code")}]}>
						<Input
							placeholder={t("Code")}
							data-e2e="mobile-code"
							className="theme-input"
							prefix={<img src={Images.phoneIcon} alt="" />}
							type="tel"
						/>
					</Form.Item>
					<Form.Item name="MobileNumber" rules={[{required: true, message: t("Please input your mobile number")}]}>
						<Input
							placeholder={t("MobileNumber")}
							id="phoneNumber"
							data-e2e="mobile-number"
							className="theme-input phone-input"
							type="tel"
						/>
					</Form.Item>
				</div>
				{/* <Form.Item name="DocumentNumber" rules={[{required: false, message: t("Please input your document number")}]}>
					<Input
					data-e2e="document-number"
						placeholder={t("DocumentNumber")}
						className="theme-input"
						prefix={<img src={Images.documentNumberIcon} alt="" />}
					/>
				</Form.Item> */}
				<button data-e2e={t("Next")} className="authModal-btn theme-btn full-width sqr-btn no-mobile">{t("Next")}</button>
			</Form>
    </>
  );
};

export default Step2;
