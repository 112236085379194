import axios from "axios";

class LiveCasinoApi {
  getProductURL = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}
const api = new LiveCasinoApi();
export default api;
