import { Select, Input } from "antd";
import { t } from "i18next";
import React from "react";


const ResponsibleGame = ({ editStatus, handleEditStatus }) => {
  const { Option } = Select;

  return (
    <div className="profile-game profile-pd">
			<p className="cabinetForm-title">{t("Limitation")}</p>
			<div className="profile-game-fields">
				<div className="profile-game-row">
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Limit Type")}</p>
						<Select
							className="theme-input no-border"
							placeholder={t("Select Limit Type")}
						>
							<Option value="USDT">{t("Total Deposit")}</Option>
						</Select>
					</div>
					<div className="inputGroup">
						<p className="inputGroup-label">{t("Minutes")}</p>
						<Input
							className="theme-input no-border"
							placeholder={t("Minutes")}
						/>
					</div>
				</div>
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Daily")}</p>	
					<Input className="theme-input no-border" placeholder={t("Daily")} />
				</div>
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Weekly")}</p>	
					<Input
						className="theme-input no-border"
						placeholder={t("Weekly")}
					/>
				</div>
				<div className="inputGroup">
					<p className="inputGroup-label">{t("Monthly")}</p>	
					<Input
						className="theme-input no-border"
						placeholder={t("Monthly")}
					/>
				</div>
			</div>
			<div className="profile-settings-btns">
				<button
          className="cabinet-btn"
          data-e2e={t("Save-Changes")}
        >
          {t("Save-Changes")}
        </button>
				<button className="cabinet-btn outlined" data-e2e={t("Cancel-changes")}>
					<span>{t("Cancel-changes")}</span>
				</button>
			</div>
    </div>
  );
};

export default ResponsibleGame;
