import { Pagination, Table } from "antd";
import { Images } from "../../../../Images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MainHistory = ({
  Payhistory,
  navEl,
  empty,
  History,
  SkipCount,
  setSkipCount,
  img,
}) => {
  const { shape, noAccounts, noTicket } = Images;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { t } = useTranslation();
  let locale = {
    emptyText: (
      <span>
        <p className="d-flex justify-content-center align-items-center text-light flex-column">
          <img
            className={`${
              themeMode === "light" ? "black-image" : ""
            } shape-img my-4`}
            src={
              img === "account"
                ? noAccounts
                : img === "ticket"
                ? noTicket
                : shape
            }
            alt=""
          />
          <p className="no-results">{t(empty)}</p>
        </p>
      </span>
    ),
  };
  return (
    <>
      <Table
        locale={locale}
        columns={navEl}
        dataSource={Payhistory}
        pagination={false}
        className="tester-assigned-please-dont-remove"
      />
      {History?.Count > 10 ? (
        <Pagination
          current={SkipCount + 1}
          total={History?.Count}
          defaultPageSize={10}
          showSizeChanger={false}
          onChange={(e) => setSkipCount(e - 1)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MainHistory;
