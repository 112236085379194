import { Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import CasherNav from "../../../modules/Casher/context/context";
import { t } from "i18next";
import { Images } from "../../../Images";
import { useSelector } from "react-redux";
import { loggedInMenu } from "../constantNavBar";

export default function NavbarProfileMenu(props) {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  const history = useHistory();
  const { setFinUrl } = useContext(CasherNav);

  const items = loggedInMenu.map((items, index) => ({
    key: `${items.name}`,
    label: (
      <button
        onClick={() => {
          items.url && history.push(items.url);
          items.inside_route && setFinUrl(items.inside_route);
          items.islogout && props.logoutHandler();
        }}
      >
        <img
          className={themeMode === "light" ? "black-image" : ""}
          width="14"
          height="14"
          src={items.icon}
          alt={items.icon}
        />
        <span data-e2e={t(items.name)}>{t(items.name)}</span>
      </button>
    ),
  }));

  return (
    <Dropdown
      overlayClassName="theme-dropdown"
      menu={{ items }}
      trigger={["click"]}
    >
      <button className="navbar-profile">
        <img
          width="42"
          height="42"
          data-e2e="Profile-icon"
          src={
            themeMode === "dark"
              ? Images.navProfileIcon
              : Images.navProfileIconLight
          }
          alt="profile icon"
        />
      </button>
    </Dropdown>
  );
}
