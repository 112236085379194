import axios from "axios";

class inplayApi {
  getInplayURL = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

const api = new inplayApi();
export default api;
