import { put, takeLatest, call } from "redux-saga/effects";
import { InplayActions } from "../types";
import inplayApi from "../api";
import { getError, openNotificationWithIcon } from "../../../../utils/helper";

function* getInplayURL(action) {
  try {
    const response = yield call(inplayApi.getInplayURL, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: InplayActions.GET_INPLAY_URL_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getInplayURL saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: InplayActions.GET_INPLAY_URL_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* InplaySaga() {
  yield takeLatest(InplayActions.GET_INPLAY_URL, getInplayURL);
}
