import { InplayActions } from "../types";

const initialState = {
  inplayData: [],
  inplayURL: false,
};

const InplayReducer = (state = initialState, action) => {
  switch (action.type) {
    case InplayActions.GET_INPLAY_URL: {
      return {
        ...state,
        inplayURL: false,
        inplayData: action.payload,
      };
    }
    case InplayActions.GET_INPLAY_URL_SUCCESS: {
      return {
        ...state,
        inplayURL: true,
        inplayData: action.payload,
      };
    }
    case InplayActions.GET_INPLAY_URL_ERROR: {
      return {
        ...state,
        inplayURL: false,
        inplayData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default InplayReducer;
