import { HomeActions } from "../types";

const initialState = {
  gameUrl: [],
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomeActions.GET_TOP_GAMES: {
      return {
        ...state,
        gameUrl: action.payload,
      };
    }
    case HomeActions.GET_TOP_GAMES_SUCCESS: {
      return {
        ...state,
        gameUrl: action.payload,
      };
    }
    case HomeActions.GET_TOP_GAMES_ERROR: {
      return {
        ...state,
        gameUrl: action.payload,
      };
    }
    default:
      return state;
  }
};

export default HomeReducer;
