import { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../../helpers/outsideClick";
import { Link } from "react-router-dom";
import GameCard from "../../Game/GameCard";
import { Images } from "../../../Images";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { connect } from "react-redux";
import { replaceGamesKeysNames } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { getGames } from "../actions";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import routes from "../../../Route/URLs";
const NavbarSearch = (props) => {
  const { t } = useTranslation();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { getGames, gamesData, isTablet, inputTabletClickHandler, loading } = props;

  const [activeSearch, setActiveSearch] = useState(false);
  const [searchText, setsearchText] = useState();
  // const testGames = gamesTestData.slice(0, 8);
  const Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");
  const refSearch = useRef();
  useOutsideClick(refSearch, () => {
    setActiveSearch(false);
  });

  let allGames = gamesData?.Games;
  const gameElements = allGames
    ? allGames.map((game) => {
        let result = replaceGamesKeysNames(game);
        return (
          <GameCard
            key={result.name}
            {...result}
            navbarcard={true}
          />
        );
      })
    : [];

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: 8,
      WithWidget: false,
      CategoryId: "",
      IsForMobile: false,
      Pattern: searchText,
      LanguageId: LanguageId,
      ProviderIds: null,
    };
    if (Token) {
      payload = {
        ...payload,
        ClientId: ClientId || "",
        Token: Token || "",
      };
    }
    await getGames(payload);
  };
  useEffect(() => {
    getGamesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fastLinks = [
    { keyName: "Casino", path: routes.CASINO },
    { keyName: "Live Casino", path: routes.LIVE_CASINO },
    { keyName: "Games", path: routes.GAMES },
  ];

  return (
    <>
      <button
        ref={refSearch}
        onClick={() => setActiveSearch(true)}
        className="navbar-search">
        <input
          type="text"
          placeholder={t("Search for games")}
          value={searchText}
          onChange={(e) => setsearchText(e.target.value)}
          onClick={inputTabletClickHandler}
        />
        <img
          className="navbar-search-icon"
          width="18"
          height="18"
          src={Images.searchIcon}
          alt="search icon"
        />
        {activeSearch && !isTablet && (
          <div className="navbar-search-res">
            <p className="navbar-search-title">{t("Recommended")}</p>
            {loading && (
              <div className="d-flex justify-content-center w-100 d-block">
                <Spin
                  tip={t("Loading")}
                  data-e2e="games-present"
                />
              </div>
            )}
            <div className={`navbar-search-games ${gameElements.length < 1 ? "no-grid" : ""}`}>
              {!loading && gameElements.length > 0 && gameElements}
              {!loading && gameElements.length < 1 && <p className="no-results">{t("No search results found")}</p>}
            </div>
            <div className="divider"></div>
            <p className="navbar-search-title">{t("Fast links")}</p>
            <div className="navbar-search-links">
              {fastLinks.map((link) => {
                return (
                  <div
                    key={`${link.name}-d`}
                    className="d-flex">
                    <Link
                      to={link.path}
                      className="navbar-search-link">
                      <img
                        className={themeMode === "light" ? "black-image" : ""}
                        width="25"
                        height="14"
                        src={Images.fastLinkIcon}
                        alt="fast link icon"
                      />
                      {t(link.keyName)}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.headerReducer?.searchbarGames,
    loading: state?.headerReducer?.loading,
  };
};

export default connect(mapStateToProps, {
  getGames,
})(NavbarSearch);
