import browser from "browser-detect";
import React, { useEffect, useState } from "react";
import { saveActivity } from "../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../Components/Common/getIp";
import BasicLayout from "../../RivieraBetBasicLayout";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import "./Casino.scss";
import GameNavbar from "./Games/GameNavbar";
import CasinoHeader from "./CasinoHeader";
const browserInfo = browser();

const Index = () => {
  const [searchText, setSearchText] = useState("");
	useEffect(() => {
    let path = window?.location?.pathname;
    let pageName = path?.split("/")[2] || "";
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetGames",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  }, []);

  return (
    <BasicLayout pageTitle="Casino">
      <div className="casino-page">
        <CasinoHeader searchText={searchText} setSearchText={setSearchText} />
        <GameNavbar searchText={searchText} setSearchText={setSearchText} />
      </div>
    </BasicLayout>
  );
};

export default Index;
