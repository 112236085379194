import { Modal } from 'antd'
import React from 'react'
import { Images } from '../../../Images'
import moment from 'moment'

const BonusInfoModal = (props) => {
    const { infomodal, data, setInfomodal } = props

    return (
        <Modal
            open={infomodal}
            footer={null}
            width="500px"
            centered
            className="info-mofal"
            onCancel={() => setInfomodal(false)}
        >
            <div className="">
                <div className="">
                    <div className="">
                        <h1 className="heading">
                            BONUS INFO
                        </h1>
                        <img src={Images.closeIcon}
                            onClick={() => setInfomodal(false)}
                            alt="closeIcon" />
                    </div>
                    <div className="border rounded p-2">
                        <div className="d-flex justify-content-between align-content-center">
                            <h1 className='subheading'>ID</h1>
                            <p className='subheading'>{data?.Bonus?.Id}</p>
                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Name</h1>
                            <p className='subheading'>{data?.Bonus?.Name}</p>
                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Turnover left</h1>
                            <p className='subheading'>{data?.Bonus?.TurnoverAmountLeft}</p>

                        </div>
                    </div>
                </div>
                <div className="pt-3">
                    <h1 className='heading pb-2'>TRIGGERS</h1>
                    <div className="border rounded p-2">
                        <div className="d-flex justify-content-between align-content-center">
                            <h1 className='subheading'>Name</h1>
                            <p className='subheading'>{data?.Triggers[0]?.Name}</p>

                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Type</h1>
                            <p className='subheading'>{data?.Triggers[0]?.TypeName}</p>

                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Percent</h1>
                            <p className='subheading'>{data?.Triggers[0]?.Percent}</p>

                        </div>
                        <div className="d-flex justify-content-between align-content-center">
                            <h1 className='subheading'>Start Time</h1>
                            <p className='subheading'>{moment(data?.Triggers[0]?.StartTime).format("LLL")}</p>

                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Finish Time</h1>
                            <p className='subheading'>{moment(data?.Triggers[0]?.FinishTime).format("LLL")}</p>

                        </div>
                        <div className="d-flex justify-content-between align-content-">
                            <h1 className='subheading'>Status</h1>
                            <p className='subheading'>{data?.Triggers[0]?.StatusName}</p>

                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default BonusInfoModal