import React from "react";
import { t } from "i18next";
import { NavLink } from "react-router-dom";
import { sidebarRoutes } from "../../RivieraBetLayout/Constant";

const MobileNavbar = () => {
  return (
    <>
      <div className="casino-navT casino-scroll-horizontal">
        {sidebarRoutes.map((item) => (
          <NavLink exact activeClassName="active" className="casino-navT-link" to={item.url} key={item.key}>
            <img
              width="25"
              height="25"
              loading="lazy"
              src={item.icon}
              alt={t(item.name)}
            />
            <p>{t(item.name)}</p>
          </NavLink>
        ))}
      </div>
      <div className="casino-divider casino-divider-left"></div>
    </>
  );
};

export default MobileNavbar;
