import { Images } from "../../Images";
import { useSelector } from "react-redux";

export default function AuthModalImg() {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
	return (
		<div
			className={`authModal-img ${themeMode === 'light' ? 'light-theme' : ''}`}
			style={{ backgroundImage: `url(${Images.modalBackground})` }}
		>
			<img
				width="240"
				height="123"
				className="authModal-logo"
				src={Images.modalLogo}
				alt="logo"
				loading="lazy"
			/>
		</div>
	)
}