import { headeractions } from "../types";

const initialState = {
  getClientBalanceData: [],
  isClientBalance: false,
  searchbarGames: [],
  loading: false,
};

const HeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case headeractions.GET_CLIENT_BALANCE: {
      return {
        ...state,
        isClientBalance: false,
        getClientBalanceData: action.payload,
      };
    }
    case headeractions.GET_CLIENT_BALANCE_SUCCESS: {
      return {
        ...state,
        isClientBalance: true,
        getClientBalanceData: action.payload,
      };
    }
    case headeractions.GET_CLIENT_BALANCE_ERROR: {
      return {
        ...state,
        isClientBalance: false,
        getClientBalanceData: action.payload,
      };
    }
    case headeractions.GET_SEARCHBAR_GAMES: {
      return {
        ...state,
        loading: true,
      };
    }
    case headeractions.GET_SEARCHBAR_GAMES_SUCCESS: {
      return {
        ...state,
        searchbarGames: action.payload,
        loading: false,
      };
    }
    case headeractions.GET_SEARCHBAR_GAMES_ERROR: {
      return {
        ...state,
        searchbarGames: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};
export default HeaderReducer;
