import BasicLayout from "../../../RivieraBetBasicLayout";
import "./Support.scss";
import ContactSocial from "./SupportRigth/ContactSocial/ContactSocial";
import SupportRight from "./SupportRigth/SupportRight";

const Support = () => {
  return (
    <BasicLayout pageTitle="Support">
			<div className="support">
				<div className="content-px content-py uw-content">
					<p className="layout-title">Support</p>
					<div className="support-grid">
						<SupportRight />
						<ContactSocial />
					</div>
				</div>
			</div>
    </BasicLayout>
  );
};

export default Support;

