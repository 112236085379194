import { UpdateProfileActions } from "../types";

export const updateProfileInfo = (payload) => {
  return { type: UpdateProfileActions.UPDATE_PROFILE_INFO, payload: payload };
};

export const updateProfileInfoSuccess = (payload) => {
  return {
    type: UpdateProfileActions.UPDATE_PROFILE_INFO_SUCCESS,
    payload: payload,
  };
};

export const updateProfileInfoError = (payload) => {
  return {
    type: UpdateProfileActions.UPDATE_PROFILE_INFO_ERROR,
    payload: payload,
  };
};

export const sendVerificationCode = (payload) => {
  return { type: UpdateProfileActions.SEND_VERIFICATION_CODE, payload: payload };
};

export const sendVerificationCodeSuccess = (payload) => {
  return {
    type: UpdateProfileActions.SEND_VERIFICATION_CODE_SUCCESS,
    payload: payload,
  };
};

export const sendVerificationCodeError = (payload) => {
  return {
    type: UpdateProfileActions.SEND_VERIFICATION_CODE_ERROR,
    payload: payload,
  };
};
export const documentType = (payload) => {
  return { type: UpdateProfileActions.DOCUMENT_TYPE, payload: payload };
};

export const documentTypeSuccess = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_TYPE_SUCCESS,
    payload: payload,
  };
};

export const documentTypeError = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_TYPE_ERROR,
    payload: payload,
  };
};

export const submitDocument = (payload) => {
  return { type: UpdateProfileActions.SUBMIT_DOCUMENT, payload: payload };
};


export const getdocumentHistory = (payload) => {
  return { type: UpdateProfileActions.DOCUMENT_HISTORY, payload: payload };
};

export const getdocumentHistorySuccess = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_HISTORY_SUCCESS,
    payload: payload,
  };
};

export const getdocumentHistoryError = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_HISTORY_ERROR,
    payload: payload,
  };
};

export const getdocumentStatus = (payload) => {
  return { type: UpdateProfileActions.DOCUMENT_STATUS, payload: payload };
};

export const getdocumentStatusSuccess = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_STATUS_SUCCESS,
    payload: payload,
  };
};

export const getdocumentStatusError = (payload) => {
  return {
    type: UpdateProfileActions.DOCUMENT_STATUS_ERROR,
    payload: payload,
  };
};