import { useTranslation } from "react-i18next";
import { chooseImg } from "../../../../lib/constants";
//todo: kristi: choose image refactor
export default function DepositFormHeader({ paymentSystemName }) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className="cabinetForm-title"
        id="payment-title">
        {t("Enter_payment")}
      </div>
      <div className="pb-3">
        <img
          data-e2e={paymentSystemName}
          width="100%"
          src={chooseImg(paymentSystemName)}
          alt="payment icon"
        />
      </div>
    </div>
  );
}
