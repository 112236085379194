import { put, takeLatest, call } from "redux-saga/effects";
import { headeractions } from "../types";
import headerApi from "../api";
import { getError } from "../../../utils/helper";

function* getClientBalance(action) {
  try {
    const response = yield call(headerApi.getClientBalance, action.payload);
    yield put({
      type: headeractions.GET_CLIENT_BALANCE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getClientBalance saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: headeractions.GET_CLIENT_BALANCE_ERROR,
      payload: customizeError,
    });
    return false;
  }
}

function* getGames(action) {
  try {
    const response = yield call(headerApi.getGames, action.payload);
    yield put({
      type: headeractions.GET_SEARCHBAR_GAMES_SUCCESS,
      payload: response?.data?.ResponseObject,
    });
    return true;
  } catch (error) {
    console.error("error in casinoGames saga :: ", error);
    yield put({
      type: headeractions.GET_SEARCHBAR_GAMES_ERROR,
      payload: error,
    });
    return false;
  }
}

export function* headerSaga() {
  yield takeLatest(headeractions.GET_CLIENT_BALANCE, getClientBalance);
  yield takeLatest(headeractions.GET_SEARCHBAR_GAMES, getGames);
}
