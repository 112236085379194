import axios from "axios";

async function generateActivityToken(userData) {
  if (!localStorage.getItem("activity_tracking_token") || localStorage.getItem("activity_tracking_token") === "") {
    await axios({
      method: "post",
      url: `${localStorage.getItem("api_url")}/act/get-activity-trackingToken`,
      data: { userData: userData },
    })
      .then((res) => {
        localStorage.setItem("activity_tracking_token", res.data.token);
      })
      .catch((err) => console.log(err));
  }
}

export async function saveActivity(activityObject) {
  try {
    const token = localStorage.getItem("Token");
    const userData = JSON.parse(localStorage.getItem("userData"));
    let activityObj = {
      EventPage: activityObject.EventPage,
      EventType: activityObject.EventType,
      attribute1: activityObject.attribute1 ? activityObject.attribute1 : "",
      attribute2: activityObject.attribute2 ? activityObject.attribute2 : "",
      attribute3: activityObject.attribute3 ? activityObject.attribute3 : "",
      attribute4: activityObject.attribute4 ? activityObject.attribute4 : "",
      attribute5: activityObject.attribute5 ? activityObject.attribute5 : "",
      attribute6: activityObject.attribute6 ? activityObject.attribute6 : "",
    };
    activityObj.deviceType = activityObject.device_type ? activityObject.device_type : "";
    activityObj.browserType = activityObject.browser_type ? activityObject.browser_type : "";
    activityObj.browserVersion = activityObject.browser_version ? activityObject.browser_version : activityObject.browser_version;
    activityObj.language = activityObject.language ? activityObject.language : "";
    activityObj.ipAddress = activityObject.ip_address ? activityObject.ip_address : "";
    activityObj.sessionId = token;
    activityObj.trackerId = localStorage.getItem("trackerId");
    const deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));
    activityObj = { ...activityObj, ...deviceInfo };
    const activity_tracking_token = localStorage.getItem("activity_tracking_token");
    if (!activity_tracking_token || activity_tracking_token === "") {
      await generateActivityToken(userData);
    }
    await axios({
      method: "post",
      url: `${localStorage.getItem("activity_tracking_api_url")}/act/activitytracking`,
      data: { activity_tracking_token, activityTrack: [activityObj] },
    });
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}
