import { CasherProvider } from "./context/context";
import Main from "./Main";

const Casher = () => {
  return (
    <CasherProvider>
      <Main />
    </CasherProvider>
  );
};

export default Casher;
