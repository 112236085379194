import { useSelector } from "react-redux";
import BasicLayout from "../../../RivieraBetBasicLayout";
import { accountPayoutBonus } from "../lib/constants";

const AccountPayoutAndBonus = () => {
  const BaseName = useSelector((state) => state?.config?.BASE_PRODUCT_NAME) || "";
  return (
    <BasicLayout>
      <div className="content-px content-py uw-content legal">
        <div className="layout-title">Terms of Service</div>
        <div className="legal-block">
          {
            // eslint-disable-next-line
            accountPayoutBonus?.map((term, i) => {
              if (i < 2)
                return (
                  <div id={term.title}>
                    <div className={`legal-title ${i > 0 && "mt-5"}`}>{term.title}</div>
                    {accountPayoutBonus[0]?.expl?.map((e, j) => (
                      <p key={e}>
                        {/* {j + 1}. {e.replace(/casinoName/g, BaseName)} */}
                        {e.replace(/casinoName/g, BaseName)}
                      </p>
                    ))}
                  </div>
                );
              if (i === 2)
                return (
                  <div id={term.title}>
                    <div className={`legal-title ${i > 0 && "mt-5"}`}>{term.title}</div>
                    {accountPayoutBonus[0]?.expl?.map((e, j) => (
                      <p key={e}>{e.replace(/casinoName/g, BaseName)}</p>
                    ))}
                  </div>
                );
            })
          }
        </div>
      </div>
    </BasicLayout>
  );
};

export default AccountPayoutAndBonus;
