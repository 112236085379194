import { CasinoActions } from "../types";

const initialState = {
  casinoGames: [],
  loading: false,
  scrollloading: false,
};

const CasinoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CasinoActions.GET_CASINO_GAMES: {
      console.log(action.payload, "pay");
      return {
        ...state,
        loading: action.payload.PageSize < 25 && true,
        scrollloading: true,
      };
    }
    case CasinoActions.GET_CASINO_GAMES_SUCCESS: {
      return {
        ...state,
        casinoGames: action.payload,
        loading: false,
        scrollloading: false,
      };
    }
    case CasinoActions.GET_CASINO_GAMES_ERROR: {
      return {
        ...state,
        casinoGames: action.payload,
        loading: false,
        scrollloading: false,
      };
    }
    default:
      return state;
  }
};

export default CasinoReducer;
