import React from "react";
import BasicLayout from "../../RivieraBetBasicLayout";

const SinglePromotion = () => {
  return (
    <BasicLayout pageTitle="Promotions">
      <div className="subscribe pt-4">
        <div className="single-promotions-container pb-4">
          <div className="d-flex justify-content-between">
            <img
              src=""
              alt="arrow"
              width={12}
              height={20}
              className="flex-shrink-0"
            />
            <div className="single-promotion-header flex-grow-1 ms-5">
              Mega Fire Blaze Roulette ( live )
            </div>
          </div>
          <div className="single-promotions-section mt-4 p-4">
            <div className="single-promotions-section-img">
              <img
                src="https://via.placeholder.com/360"
                alt=""
                width={932}
                height={427}
              />
            </div>
            <div className="single-promotion-content p-4">
              <p className="single-promotion-header">
                Mega Fire Blaze Roulette ( live )
              </p>
              <p>
                Take the reels to the next level with an awesome 50% Reload
                Bonus of up to €50 when making a deposit.
              </p>
              <p className="single-promotion-header">LEARN MORE</p>
            </div>
          </div>
        </div>
        <div className="single-promotion-cards"></div>
        <div className="pb-4"></div>
      </div>
    </BasicLayout>
  );
};

export default SinglePromotion;
