import { put, takeLatest, call } from "redux-saga/effects";
import casinoGamesApi from "../api";
import { openNotificationWithIcon, getError } from "../../../utils/helper";
import { CasinoActions } from "../types";

function* casinoGames(action) {
  try {
    const response = yield call(casinoGamesApi.getCasinoGames, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: CasinoActions.GET_CASINO_GAMES_SUCCESS,
      payload: response?.data?.ResponseObject,
    });
    return true;
  } catch (error) {
    console.error("error in casinoGames saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasinoActions.GET_CASINO_GAMES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* casinoSaga() {
  yield takeLatest(CasinoActions.GET_CASINO_GAMES, casinoGames);
}
