import * as React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loginModal } from "../../Components/Login/actions/index";
import {
  addItemToLocalStorage,
  clearAllFromLocalStorage,
  getItemFromLocalStorage,
} from "../../utils/localStorage";
import { openNotificationWithIcon } from "../../utils/helper";
class AxiosInterceptor extends React.Component {
  axiosInterceptorRequest;
  axiosInterceptorResponse;
  updateAxiosInterceptors = () => {
    try {
      axios.interceptors.request.eject(this.axiosInterceptorRequest);
      axios.interceptors.response.eject(this.axiosInterceptorResponse);
      this.axiosInterceptorResponse = axios.interceptors.response.use(
        (response) => {
          const ResponseCode = response?.data?.ResponseCode;
          if (ResponseCode === 28 || ResponseCode === 29) {
            const Theme = getItemFromLocalStorage("Theme");
            const i18nextLng = getItemFromLocalStorage("i18nextLng");
            clearAllFromLocalStorage();
            window.location.reload();
            addItemToLocalStorage("Theme", Theme);
            addItemToLocalStorage("i18nextLng", i18nextLng);
            openNotificationWithIcon("error", "Error", "Session Expired");
            this.props.loginModal(true);
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          // if (error?.response?.status === 400) {
          //   clearAllFromLocalStorage();
          //   openNotificationWithIcon("error", "Error", "Session Expired");
          //   this.props.loginModal(true);
          // }
          return Promise.reject(error.response);
        }
      );
    } catch (error) {
      console.error("Error", error);
    }
  };

  componentDidMount() {
    this.updateAxiosInterceptors();
  }

  componentDidUpdate() {
    this.updateAxiosInterceptors();
  }
  render() {
    return <>{this.props.children}</>;
  }
}

export default connect(null, {
  loginModal,
})(AxiosInterceptor);
