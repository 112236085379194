export const configReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        api_url: "https://localhost:4201",
        activity_tracking_api_url: "https://localhost:4201",
      };
  }
};
