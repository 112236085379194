export const BonusActions = {
  GET_BONUS_STATUS_LIST: "GET_BONUS_STATUS_LIST",
  GET_BONUS_STATUS_LIST_SUCCESS: "GET_BONUS_STATUS_LIST_SUCCESS",
  GET_BONUS__STATUS_LIST_ERROR: "GET_BONUS_STATUS_LIST_ERROR",
  GET_BONUSES: "GET_BONUSES",
  GET_BONUSES_ERROR: "GET_BONUS_ERROR",
  GET_BONUSES_SUCCESS: "GET_BONUSES_SUCCESS",
  GET_PROMOCODE: "PROMOCODE",
  GET_PROMOCODE_ERROR: "PROMOCODE_ERROR",
  GET_PROMOCODE_SUCCESS: "PROMOCODE_SUCCESS",
  GET_INFO: "INFO",
  GET_INFO_ERROR: "INFO_ERROR",
  GET_INFO_SUCCESS: "INFO_SUCCESS",
};
