import "./scss/cabinet.scss";
import { useContext } from "react";
import Wallets from "./Finances/Wallets";
import CasherNav from "./context/context";
import History from "./History/History";
import Bonuses from "./Bonuses/Bonuses";
import Ticket from "./Ticket/Ticket";
import Profile from "./Profile/Profile";
import Balance from "./Balance/Balance";
import Loading from "../../Components/Common/Loading";

const Main = () => {
  const { navURL, selectedCurrency, loading } = useContext(CasherNav);

  const main = () => {
    if (navURL === "Wallets")
      return <Wallets selectedCurrency={selectedCurrency} />;
    if (navURL === "History") return <History />;
    if (navURL === "Bonuses") return <Bonuses />;
    if (navURL === "Ticket") return <Ticket />;
    if (navURL === "Profile") return <Profile />;
    if (navURL === "Balance") return <Balance />;
  };

  return loading ? <Loading /> : <div className="main-container">{main()}</div>;
};

export default Main;
