import { useContext } from "react";
import CasherNav from "../../context/context";
import RightResponsible from "./ResponsibleGame/ResponsibleGame";
import ProfilePasswordForm from "./Settings/ProfilePasswordForm";

const RightSection = ({ editStatus, handleEditStatus, settingSelect }) => {
  const { profileUrl } = useContext(CasherNav);
  const main = () => {
    if (profileUrl === "Settings")
      return <ProfilePasswordForm settingSelect={settingSelect} />;
    if (profileUrl === "Responsible Gaming")
      return (
        <RightResponsible
          editStatus={editStatus}
          handleEditStatus={handleEditStatus}
        />
      );
  };

  return (
		<div>
			{main()}
		</div>
  );
};

export default RightSection;
