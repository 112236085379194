import { sportsActions } from "../types";

const initialState = {
  getSportsProductURLData: [],
  getSportsProductURL: false,
};

const SportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case sportsActions.GET_SPORTS_PRODUCT_URL: {
      return {
        ...state,
        getSportsProductURL: false,
        getSportsProductURLData: action.payload,
      };
    }
    case sportsActions.GET_SPORTS_PRODUCT_URL_SUCCESS: {
      return {
        ...state,
        getSportsProductURL: true,
        getSportsProductURLData: action.payload,
      };
    }
    case sportsActions.GET_SPORTS_PRODUCT_URL_ERROR: {
      return {
        ...state,
        getSportsProductURL: false,
        getSportsProductURLData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SportsReducer;
