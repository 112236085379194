import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { disputeResolution } from "../lib/constants";

const DisputeResolution = () => {
  return (
    <CommonFooterTermsAndConditions title={disputeResolution[0]?.title} data={disputeResolution} />
  );
};

export default DisputeResolution;
