import { HomeActions } from "../types";

export const getGames = (payload) => {
  return {
    type: HomeActions.GET_TOP_GAMES,
    payload: payload,
  };
};

export const getGamesSuccess = (payload) => {
  return {
    type: HomeActions.GET_TOP_GAMES_SUCCESS,
    payload: payload,
  };
};

export const getGamesError = (payload) => {
  return { type: HomeActions.GET_TOP_GAMES_ERROR, payload: payload };
};

