import axios from "axios";

class sportsApi {
  getSportsProductURL = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

const api = new sportsApi();
export default api;
