import { Input, Select } from "antd";
import browser from "browser-detect";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";

import { Option } from "antd/lib/mentions";
import {
  getAllCities,
  getAllCountries
} from "../../../../../Components/Login/actions";
import {
  sendVerificationCode,
  updateProfileInfo
} from "../../Services/actions";
import CustomDatePicker from "../../../../../Components/Common/CustomDatePicker";
import { format } from 'date-fns';

const browserInfo = browser();

const SettingsMain = () => {
  const dispatch = useDispatch();
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const { t } = useTranslation();
  let userData = getItemFromLocalStorage("userData");

  let allCountriesData = useSelector(
    (state) => state?.loginReducer?.allCountries?.data?.ResponseObject
  );

  // let allCitiesData = useSelector(
  //   (state) => state?.loginReducer?.allCities?.data?.ResponseObject
  // );

  const [profileData, setProfileData] = useState({
    mobileNo: userData?.MobileNumber || "",
    email: userData?.Email || "",
    fName: userData?.FirstName || "",
    lName: userData?.LastName || "",
    BirthDate: userData?.BirthDate || "",
    address: userData?.Address || "",
    currency: userData?.CurrencyId || "",
    ZipCode: userData?.ZipCode || "",
    CountryId: userData?.CountryId || "",
    city: userData?.City || "",
  });

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSendVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: userData?.PartnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToMobileNumber",
      RequestData: JSON.stringify({
        MobileNumber: profileData?.mobileNo,
      }),
      TimeZone: 0,
    };
    dispatch(sendVerificationCode(payload));
  };

  const handleUpdate = async () => {
    let payload = {
      Loader: true,
      PartnerId: userData?.PartnerId,
      TimeZone: userData?.TimeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "ChangeClientDetails",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: profileData?.mobileNo,
      Code: null,
      RequestData: JSON.stringify({
        ClientId: userData?.Id,
        Email: profileData?.email,
        IsEmailVerified: userData?.IsEmailVerified,
        CurrencyId: profileData?.currency,
        BirthDate: moment(profileData?.BirthDate).format("YYYY-MM-DD[T]HH:mm:ss"),
        FirstName: profileData?.fName,
        LastName: profileData?.lName,
        Info: null,
        Address: profileData?.address,
        MobileNumber: profileData?.mobileNo,
        IsMobileNumberVerified: userData?.IsMobileNumberVerified,
        LanguageId: LanguageId,
        IsDocumentVerified: userData?.IsDocumentVerified,
        ZipCode: profileData?.ZipCode,
        EmailOrMobile: profileData?.email,
        Gender: userData?.Gender,
        CountryId: profileData?.CountryId,
        RegionId: userData?.RegionId,
        Citizenship: userData?.Citizenship,
        City: profileData?.city,
      }),
      ClientId: userData?.Id,
      Token: userData?.Token || localStorage.getItem("Token"),
    };
    dispatch(updateProfileInfo(payload));
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Profile_SaveChanges",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };

  let payload = { LanguageId: "en", PartnerId: 43, TimeZone: 0 };

  const handleCities = (value) => {
    setProfileData({...profileData, CountryId: value})
    dispatch(
      getAllCities({
        ...payload,
        ParentId: value,
        TypeId: 3,
      })
    );
  };
// const handleChangeCity = (value) => {
//   setProfileData({...profileData, CityId: value})
// }

  useEffect(() => {
    dispatch(getAllCountries({ ...payload, TypeId: 5 }));
    dispatch(
      getAllCities({
        ...payload,
        ParentId: userData?.CountryId,
        TypeId: 3,
      })
    ); // eslint-disable-next-line
  }, []);

  const onChangeDob = (value) => {
    setProfileData(prev => {
      return {
        ...prev,
        BirthDate: value
      }
    })
  };

  return (
    <div className="profile-settings profile-pd">
      <p className="cabinetForm-title">{t("PersonalDetails")}</p>
      <div className="profile-settings-fields">
        <div className="inputGroup">
          <p className="inputGroup-label">{t("FirstName")}</p>
          <Input
            className="theme-input no-border"
            type="text"
            placeholder={t("Firstname-placeholder")}
            data-e2e="first-name"
            name="fName"
            value={profileData.fName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("LastName")}</p>
          <Input
            className="theme-input no-border"
            type="text"
            placeholder={t("Lastname-placeholder")}
            data-e2e="last-name"
            name="lName"
            value={profileData.lName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Date-of-birth")}</p>
          <CustomDatePicker
              formattedDate={profileData.BirthDate ? format(new Date((profileData.BirthDate).toString()), 'y-MM-dd') : ""}
              selected={profileData?.BirthDate}
              onSelect={onChangeDob}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label" data-e2e={t("Currency")}>
            {t("Currency")}
          </p>
          <Input
            className="theme-input no-border"
            placeholder="Eur"
            value={profileData?.currency}
            name={t("Currency")}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Email-verify")}</p>
          <div className="cabinet-blockBtn">
            <Input
              className="theme-input no-border"
              data-e2e="email"
              type="email"
              name="email"
              placeholder={t("Email-placeholder")}
              value={profileData.email}
              onChange={(e) => handleChange(e)}
            />
            <button data-e2e={t("Verify")} className="cabinet-btn">
              {t("Verify")}
            </button>
          </div>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Mobile-number-verify")}</p>
          <div className="cabinet-blockBtn">
            <Input
              className="theme-input no-border"
              type="text"
              placeholder={t("Mobile-number-placeholder")}
              value={profileData?.mobileNo}
              name="mobileNo"
              onChange={(e) => handleChange(e)}
              data-e2e="mobile-number"
            />
            <button
              className="cabinet-btn"
              onClick={handleSendVerification}
              data-e2e={t("Send")}
            >
              {t("Send")}
            </button>
          </div>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">Country</p>
          <Select
            className="theme-input suffix-select"
            showSearch
            data-e2e="country"
            placeholder={t("Country-option")}
            optionFilterProp="children"
            onChange={handleCities}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={profileData?.CountryId}
          >
            {allCountriesData?.map(({ Id, Name }) => (
              <Option value={Id} key={Id} data-e2e={Name}>
                {Name}
              </Option>
            ))}
          </Select>
        </div>
        {/*<div className="inputGroup">*/}
        {/*  <p className="inputGroup-label">City</p>*/}
        {/*  <Select*/}
        {/*    className="theme-input suffix-select"*/}
        {/*    showSearch*/}
        {/*    data-e2e="country"*/}
        {/*    placeholder="Cities"*/}
        {/*    optionFilterProp="children"*/}
        {/*    onChange={handleChangeCity}*/}
        {/*    filterOption={(input, option) =>*/}
        {/*      option.children.toLowerCase().includes(input.toLowerCase())*/}
        {/*    }*/}
        {/*    value={profileData?.CityId}*/}
        {/*  >*/}
        {/*    {allCitiesData?.map(({ Id, Name }) => (*/}
        {/*      <Option value={Id} key={Id} data-e2e={Name}>*/}
        {/*        {Name}*/}
        {/*      </Option>*/}
        {/*    ))}*/}
        {/*  </Select>*/}
        {/*</div>*/}

        <div className="inputGroup">
          <p className="inputGroup-label">{t("City")}</p>
          <Input
              className="theme-input no-border"
              placeholder={t("City")}
              data-e2e="country"
              type="text"
              name="city"
              value={profileData.city}
              onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Address")}</p>
          <Input
            className="theme-input no-border"
            placeholder={t("Address-placeholder")}
            data-e2e="address"
            type="text"
            name="address"
            value={profileData.address}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">ZipCode</p>
          <Input
            className="theme-input no-border"
            placeholder="ZipCode"
            data-e2e="ZipCode"
            type="text"
            name="ZipCode"
            value={profileData.ZipCode}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="profile-settings-btns">
        <button
          className="cabinet-btn"
          onClick={() => handleUpdate()}
          data-e2e={t("Save-Changes")}
        >
          {t("Save-Changes")}
        </button>
        <button className="cabinet-btn outlined" data-e2e={t("Cancel-changes")}>
          <span>{t("Cancel-changes")}</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsMain;
