import { put, takeLatest, call } from "redux-saga/effects";
import { sportsActions } from "../types";
import sportsApi from "../api";
import { openNotificationWithIcon, getError } from "../../../utils/helper";

function* getSportsProductURL(action) {
  try {
    const response = yield call(sportsApi.getSportsProductURL, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: sportsActions.GET_SPORTS_PRODUCT_URL_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getSportsProductURL saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: sportsActions.GET_SPORTS_PRODUCT_URL_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* sportsSaga() {
  yield takeLatest(sportsActions.GET_SPORTS_PRODUCT_URL, getSportsProductURL);
}
