import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import BasicLayout from "../../../RivieraBetBasicLayout";
import CasherNav from "../context/context";
import { walletsNavEl } from "../lib/constants";
import CabinetNav from "../components/CabinetNav";
import CabinetNavComponents from "../components/CabinetNavComponents";
import AccountsTable from "./components/AccountsTable";
import FormSection from "./components/FormSection";
import AddAccountModal from "./components/AddAccountModal";
import DepositPayMethods from "./components/DepositPayMethods";
import WithdrawPayMethods from "./components/WithdrawPayMethods";
// import FormPayment from "./components/FormPayment";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const Wallets = ({ selectedCurrency, setSelectedCurrency, props }) => {
  const { t } = useTranslation();

  const { finUrl, setFinUrl } = useContext(CasherNav);
  const [addAccount, setAddAccount] = useState(false);

  const main = () => {
    if (finUrl === "Deposit") return <DepositPayMethods />;
    if (finUrl === "Withdraw") return <WithdrawPayMethods />;
    if (finUrl === "Bank_Accounts") return <AccountsTable />;
  };

  const Loading = useSelector((state) => state?.cashierReducer?.depositLoading);

  return (
    <BasicLayout pageTitle="Wallet">
      <div className="position-relative">
        <div
          className="content-px content-py uw-content "
          style={{ filter: Loading && "blur(8px)" }}>
          <CabinetNav />

          <div className={`deposit ${finUrl === "Bank_Accounts" ? "no-grid" : ""}`}>
            <div className="cabinet-block">
              <div className="">
                <CabinetNavComponents
                  navEl={walletsNavEl}
                  url={finUrl}
                  setUrl={setFinUrl}
                  selectedCurrency={selectedCurrency}
                />

                {finUrl !== "Bank_Accounts" ? (
                  <div className="cabinet-subNav cabinet-subNav-payment">
                    <div className="cabinet-text">{t("Select_payment")}</div>
                    {/* <div className="inputGroup">
                      <FormPayment type={finUrl === "Deposit" ? 2 : 1} />
                    </div> */}
                  </div>
                ) : (
                  <div className="bankAccounts-add">
                    <button
                      data-e2e="all-amount"
                      onClick={() => setAddAccount(true)}
                      className="cabinet-btn">
                      + {t("Add_Account")}
                    </button>
                  </div>
                )}
                <div className="cabinet-divider"></div>
                {main()}
              </div>
              <AddAccountModal
                addAccount={addAccount}
                setAddAccount={setAddAccount}
              />
            </div>
            {finUrl !== "Bank_Accounts" && <FormSection finUrl={finUrl} />}
          </div>
        </div>
        {Loading && (
          <div className="d-flex justify-content-center w-100 position-absolute align-items-center top-0 bottom-0 d-block">
            <Spin tip={t("Loading")} />
          </div>
        )}
      </div>
    </BasicLayout>
  );
};

export default Wallets;
