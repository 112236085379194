import { Checkbox, Form, Select } from "antd";
import React, { useState } from "react";
import { Images } from "../../../Images/index";

import { Input } from "antd";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from "../../../utils/localStorage";
import { registerModal, signup } from "../../Login/actions";

const { Option } = Select;

const Step3 = ({ registerPayload }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const themeMode = getItemFromLocalStorage("Theme") || "";
	
		
  const onFinish = async (values) => {
    if (values?.Password !== values.confirmPassword) {
      return setErrMsg("The password confirmation does not match.");
    } else if (!termsAndConditions) {
      return setErrMsg("Please accept Terms & Conditions!");
    } else {
      setErrMsg("");
			
			const searchParams = getItemFromLocalStorage('searchParams');
			const AffiliatePlatformId = searchParams && searchParams.AffiliatePlatformId;
			const BonusCode = searchParams && searchParams.BonusCode;
			const clickid = searchParams && searchParams.clickid;
			const sourceid = searchParams && searchParams.sourceid;
			const AgentCode = searchParams && searchParams.AgentCode;
	
      let obj = {
        CurrencyId: values.CurrencyId,
        Password: values.Password,
        confirmPassword: values.confirmPassword,
        AcceptTerms: true,
        TermsConditionsAccepted: true,
        LanguageId: "en",
        TimeZone: 0,
        PromoCode: values.PromoCode,
				...(AffiliatePlatformId && {AffiliatePlatformId: AffiliatePlatformId}),
				...(BonusCode && {BonusCode: BonusCode}),
				...(clickid && {RefId: clickid}),
				...(sourceid && {AffiliateId: sourceid}),
				...(AgentCode && {AgentCode: AgentCode}),
      };
      addItemToLocalStorage("step3", obj);
      await dispatch(signup({ ...registerPayload, ...obj }));
      dispatch(registerModal(false));
    }
  };

  let step3Data = getItemFromLocalStorage("step3");

  return (
    <>
      <Form
        name="register"
        initialValues={{
          CurrencyId: step3Data?.CurrencyId,
          Password: step3Data?.Password,
          confirmPassword: step3Data?.confirmPassword,
          AcceptTerms: true,
          TermsConditionsAccepted: true,
          LanguageId: "en",
          TimeZone: 0,
          AffiliatePlatformId: "",
          PromoCode: step3Data?.PromoCode,
        }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="CurrencyId"
          rules={[
            { required: true, message: t("Please select your Currency") },
          ]}
        >
          <Select
            className="theme-input suffix-select"
            placeholder={t("Currency")}
            data-e2e="currency"
            suffixIcon={
              <img
                width="18"
                height="18"
                loading="lazy"
                src={Images.currency}
                alt="icon"
              />
            }
          >
            <Option value="EUR" data-e2e="EUR">
              EUR
            </Option>
            <Option value="AUD" data-e2e="AUD">
              AUD
            </Option>
            <Option value="USD" data-e2e="USD">
              USD
            </Option>
            <Option value="CAD" data-e2e="CAD">
              CAD
            </Option>
            <Option value="GBP" data-e2e="GBP">
              GBP
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="Password"
          rules={[{ required: true, message: t("Please enter password") }]}
        >
          <Input
            data-e2e="password"
            type="password"
            placeholder={t("Password-placeholder")}
            className="theme-input"
            prefix={<img src={Images.passwordIcon} alt="" />}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, message: t("Please enter password") }]}
        >
          <Input
            type="password"
            data-e2e="confirm-password"
            placeholder={t("Confirm-password-placeholder")}
            className="theme-input"
            prefix={<img src={Images.passwordIcon} alt="" />}
          />
        </Form.Item>
        <Form.Item
          name="PromoCode"
          rules={[
            { required: false, message: t("Please enter your Promo code") },
          ]}
        >
          <Input
            placeholder={t("PromoCode")}
            data-e2e="PromoCode"
            className="theme-input"
            prefix={<img src={Images.coupon} alt="" />}
          />
        </Form.Item>
        <Checkbox
          data-e2e="accept-t&c"
          className={`authModal-checkbox ${
            themeMode === "light" ? "light-mode" : ""
          }`}
          checked={termsAndConditions}
          onChange={() => setTermsAndConditions((prev) => !prev)}
        >
          <div
            className="authModal-text"
            dangerouslySetInnerHTML={{ __html: t("AcceptTerms") }}
          ></div>
        </Checkbox>
        {errMsg !== "" && (
          <div className="error-message text-danger pt-2" data-e2e={errMsg}>
            {errMsg}
          </div>
        )}
        <button
          className="authModal-btn theme-btn full-width sqr-btn no-mobile"
          data-e2e={t("Sign Up")}
        >
          {t("Sign Up")}
        </button>
      </Form>
    </>
  );
};

export default Step3;
