import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Images } from "../../../../Images";
import CasherNav from "../../context/context";
import { addPoint, chooseImg, currencyToSymbol } from "../../lib/constants";
import { useSelector } from "react-redux";
import { getItemFromLocalStorage } from "../../../../utils/localStorage";

const PaymentCard = ({ cardSelected, i, card, setCardSelected, CommissionPercent }) => {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { t } = useTranslation();
  let { PaymentSystemName, MinAmount, MaxAmount } = card;
  const { setSelectedPaymentMethod } = useContext(CasherNav);
  const CurrencyId = getItemFromLocalStorage("CurrencyId");

  return (
    <button
      type="button"
      key={card}
      onClick={() => {
        setSelectedPaymentMethod(card);
        setCardSelected(i);
      }}
      className={`paymentCard ${cardSelected === i ? "selected" : ""}`}>
      <img
        className="paymentCard-selectedIcon"
        src={Images.selectedIcon}
        alt="icon"
      />
      <div className="paymentCard-bg">
        <div className="paymentCard-header">
          <img
            data-e2e={PaymentSystemName}
            className={`${themeMode === "light" ? "black-image" : ""} paymentCard-logo`}
            src={chooseImg(PaymentSystemName, cardSelected, i)}
            alt="payment icon"
          />
        </div>
        {CommissionPercent >= 0 && (
          <div className="paymentCard-row">
            <p className="paymentCard-value">{CommissionPercent + " %"}</p>
            <p className="paymentCard-label">{t("CommissionPercent")}</p>
          </div>
        )}
        <div className="paymentCard-row">
          <p className="paymentCard-value">{currencyToSymbol(CurrencyId) + " " + MinAmount}</p>
          <p className="paymentCard-label">{t("Minimum")}</p>
        </div>
        <div className="paymentCard-row">
          <p className="paymentCard-value">{currencyToSymbol(CurrencyId) + " " + addPoint(MaxAmount)}</p>
          <p className="paymentCard-label">{t("Maximum")}</p>
        </div>
      </div>
    </button>
  );
};

export default PaymentCard;
