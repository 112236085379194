import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { useSelector } from "react-redux";
import { currency_symbols } from "../constantNavBar";
import { useState, useRef, useEffect } from "react";
import NavbarProfileSidebar from "./NavbarProfileSidebar";
import useOutsideClick from "../../../helpers/outsideClick";
import BigNumber from "bignumber.js";

export default function NavbarProfileTablet(props) {
  const [userbalance, setuserbalance] = useState(0);
  const [isSidebar, setIsSidebar] = useState(false);

  const firstName = getItemFromLocalStorage("FirstName");
  const lastName = getItemFromLocalStorage("LastName");
  const userName = firstName ? `${firstName[0]} ${lastName[0]}` : "N/A";
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  const isClientBalance = useSelector(
    (state) => state?.headerReducer?.isClientBalance
  );
  const AvailableBalance = useSelector(
    (state) =>
        state?.headerReducer?.getClientBalanceData?.data?.Balances?.reduce((total, balance) => {
            // Check if the balance's TypeId is 1, 2, or 12 and add it to the total
            if (balance?.TypeId === 1 || balance?.TypeId === 2 || balance?.TypeId === 12) {
              // Add balance.Balance to the total amount (as BigNumber)
              return total.plus(balance.Balance);
            }
            return total;
          }, new BigNumber(0))
);

  function toggleSidebar(bool) {
    setIsSidebar(bool);
  }

  const refSidebarProfile = useRef();
  useOutsideClick(refSidebarProfile, () => {
    setIsSidebar(false);
  });

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance?.toString());
    }
     // eslint-disable-next-line
  }, [AvailableBalance?.toString(), isClientBalance]);

  return (
    <div className="navbar-profile-t on-tablet">
      <div className="navbar-profile-t-user">
        <div className="navbar-profile-t-name">
          {firstName} {lastName}
        </div>
        <div className="navbar-profile-t-balance">
          {currency_symbols[CurrencyId] || "€"} {userbalance || 0}
        </div>
      </div>
      <div ref={refSidebarProfile}>
        <button
          onClick={() => toggleSidebar(true)}
          className="navbar-profile-t-btn"
        >
          {userName}
        </button>
        <NavbarProfileSidebar
          isSidebar={isSidebar}
          logoutHandler={props.logoutHandler}
          closeHandler={() => toggleSidebar(false)}
          userbalance={userbalance}
          user={{
            firstName,
            lastName,
            AvailableBalance,
            currency: currency_symbols[CurrencyId] || "€",
          }}
        />
      </div>
    </div>
  );
}
