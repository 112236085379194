import { ThemeActions } from "../types";

export const setTheme = (payload) => {
  return {
    type: ThemeActions.SET_THEME,
    payload: payload,
  };
};

export const themeSuccess = (payload) => {
  return { type: ThemeActions.SET_THEME_SUCCESS, payload: payload };
};

export const themeError = (payload) => {
  return { type: ThemeActions.SET_THEME_FAILURE, payload: payload };
};