import moment from "moment";
import { useState, useEffect } from "react";
import { Images } from "../../../Images";

export default function NavbarTime() {
  const [Time, setTime] = useState("");

  const time = moment().format("HH:mm");
  let timeNow = `${time}`;
  useEffect(() => {
    setInterval(() => {
      const time = moment().format("HH:mm");
      setTime(`${time}`);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="navbar-time">
      <img width="20" height="20" src={Images.timeIcon} alt="time icon" />
      <p>{Time || timeNow}</p>
    </div>
  );
}
