import { Modal } from "antd";
import React from "react";
import "./Casinomodals.scss";
import { useSelector } from "react-redux";
import { Images } from "../../Images";
import { useTranslation } from "react-i18next";


const CasinoCardInfoModel = (props) => {
  const { data, handlePlaybtn, handledemobtn } = props;
  const { gameImage, name, hasDemo, providerName, jackpotValue } = data;
	const { t } = useTranslation();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  return (
    <Modal
      open={props.infoModal}
      footer={null}
      width="700px"
      className="theme-modal casinoInfoModal"
      onCancel={() => props.setinfoModal(false)}
    >
      <div className="theme-modal-header mailModal-header">
        <p className="theme-modal-heading">{name}</p>
        <button
          type="button"
          onClick={() => props.setinfoModal(false)}
          className={`theme-modal-close ${
            themeMode === "light" ? "light-theme" : ""
          }`}
        >
          <img
            loading="lazy"
            width="17"
            height="17"
            src={Images.closeIcon}
            alt="close icon"
          />
        </button>
      </div>

      <div className="theme-modal-body">
        <div className="casinoInfoModal-row">
          <div className="casinoInfoModal-block">
            <div className="casinoInfoModal-img">
              <img src={gameImage} width="" height="" alt={name} />
            </div>
            <div>
              <button
                className="theme-btn full-width fill-btn no-mobile"
                onClick={() => handlePlaybtn()}
              >
                {t("Play Now")}
              </button>
              {hasDemo && (
                <button
                  className="casinoInfoModal-demo theme-btn full-width no-mobile"
                  onClick={handledemobtn}
                >
                  {t("Demo")}
                </button>
              )}
            </div>
          </div>

          <div className="casinoInfoModal-block">
            <p className="casinoInfoModal-title">{t("Features")}</p>
            <p className="casinoInfoModal-text">
              {t("Provider Name")}: <span>{providerName}</span>
            </p>
            <p className="casinoInfoModal-text">
              {t("Jackpot Value")}:{" "}
              <span>{jackpotValue === "" ? 0 : jackpotValue}</span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CasinoCardInfoModel;
