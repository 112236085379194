export const GamesActions = {
  GET_GAMES: "GET_GAMES",
  GET_GAMES_SUCCESS: "GET_GAMES_SUCCESS",
  GET_GAMES_ERROR: "GET_GAMES_ERROR",
  MARK_GAME_FAVOURITE: "MARK_GAME_FAVOURITE",
  MARK_GAME_FAVOURITE_SUCCESS: "MARK_GAME_FAVOURITE_SUCCESS",
  MARK_GAME_FAVOURITE_ERROR: "MARK_GAME_FAVOURITE_ERROR",
  PLAY_NOW_GAME: "PLAY_NOW_GAME",
  PLAY_NOW_GAME_SUCCESS: "PLAY_NOW_GAME_SUCCESS",
  PLAY_NOW_GAME_ERROR: "PLAY_NOW_GAME_ERROR",
  CHECK_LOGIN_STATUS: "CHECK_LOGIN_STATUS",
};
