import { NavLink } from "react-router-dom";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { getItemFromLocalStorage } from "../utils/localStorage";
import { useTranslation } from "react-i18next";
import { sidebarRoutes } from "./Constant";


export default function SidebarMenuLinks() {
  const { t } = useTranslation();

  // const promotionRoute = {
  // 	name: "Promotions",
  // 	key: MENU_KEYS.PROMOTIONS,
  // 	url: routes.PROMOTIONS,
  // 	icon: Images.promoLink,
  // };

  async function onMenuItemSelected(e) {
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: e?.key?.split("/")[2] || "",
    });
  }

  const sidebarRoutesElements = sidebarRoutes.map((link, index) => {
    return (
      <div key={link.key} onClick={(e) => onMenuItemSelected(e)}>
        <NavLink exact activeClassName="active" to={link.url} className="sidebar-link block-pd">
          <div className="sidebar-link-icon">
            <img src={link.icon} alt="icon" />
          </div>
          <span>{t(link.name)}</span>
        </NavLink>
        {index < sidebarRoutes.length - 1 && <div className="divider"></div>}
      </div>
    );
  });

  return (
    <div>
      {/* <NavLink onClick={(e) => this.onMenuItemSelected(e)} to={promotionRoute.url} key={promotionRoute.key} className="sidebar-link block-fill sidebar-link-promotions">
				<div className="sidebar-link-icon">
					<img src={promotionRoute.icon} alt="icon" />
				</div>
				<span>{promotionRoute.name}</span>
			</NavLink> */}

      <div className="sidebar-block block-fill no-pd mb-10">
        {sidebarRoutesElements}
      </div>
    </div>
  );
}
