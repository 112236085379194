import React from "react";
import { fairnessAndTesting } from "../lib/constants";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";

const FairnessAndRNG = () => {
  return (
    <CommonFooterTermsAndConditions
      title={fairnessAndTesting[0]?.title}
      data={fairnessAndTesting}
    />
  );
};

export default FairnessAndRNG;
