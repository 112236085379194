import { Select } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const PaymentFilters = ({
  paymentType,
  setpaymentSelectValue,
  paymentSelectValue,
  selectedBetStatus,
}) => {
  const { t } = useTranslation();
	
  const handleDateChange = (e) => {
    let copyobj = { ...e };
    if (e.value === "24 hours") {
      copyobj.value = "1";
    } else if (e.value === "1 month") {
      copyobj.value = "30";
    }
    setpaymentSelectValue({
      ...paymentSelectValue,
      time: e.value,
      date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
        "YYYY-MM-DD hh:mm"
      ),
      fromDate: moment(
        new Date(Date.now() - copyobj.value.split(" ")[0] * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD hh:mm"),
    });
  };

  return (
    <div className="cabinet-subNav-history-selects">
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Period-of-time")}</p>
        <Select
          className="theme-input"
          defaultValue={{
            value: paymentSelectValue.time,
          }}
          onChange={(e) => handleDateChange(e)}
          labelInValue
        >
          <Option value="24 hours">{t("24 hours")}</Option>
          <Option value="3 days">{t("3 days")}</Option>
          <Option value="7 days">{t("7 days")}</Option>
          <Option value="1 month">{t("1 month")}</Option>
        </Select>
      </div>
      <div className="inputGroup">
        <p className="inputGroup-label">{t("Status")}</p>
        <Select
          className="theme-input"
          defaultValue={{
            value: "all",
          }}
          labelInValue
          onChange={(e) =>
            setpaymentSelectValue({ ...paymentSelectValue, status: e.value })
          }
        >
          <Option value="all">{t("All")}</Option>
          {selectedBetStatus &&
            selectedBetStatus?.map((e, i) => {
              return <Option value={e.Value}>{t(e.Name)}</Option>;
            })}
        </Select>
      </div>
      <div className="inputGroup">
        <p className="inputGroup-label">{t("PaymentType")}</p>
        <Select
          className="theme-input"
          defaultValue={{
            value: "all",
          }}
          labelInValue
          onChange={(e) =>
            setpaymentSelectValue({ ...paymentSelectValue, type: e.value })
          }
        >
          <Option value="all">{t("All")}</Option>
          {paymentType &&
            paymentType?.map((e, i) => {
              return <Option value={e.Value}>{t(e.Name)}</Option>;
            })}
        </Select>
      </div>
    </div>
  );
};

export default PaymentFilters;
