import React from "react";
import { Spin } from "antd";
import "./loader.css";
import { getItemFromLocalStorage } from "../../utils/localStorage";

const Loading = () => {
  const theme = getItemFromLocalStorage("Theme");

  return (
    <div
      className={`${theme === "light" ? "spin-loader" : "spin-loader-dark"}`}
    >
      <Spin tip="Loading..." size="large"></Spin>
    </div>
  );
};

export default Loading;
