import { put, takeLatest } from "redux-saga/effects";
import { ThemeActions } from "../types";

function* themeSet(action) {
  try {
    yield put({ type: ThemeActions.SET_THEME_SUCCESS, payload: action.payload });
    return true;
  } 
    catch (error) {
      console.error("error in startSession saga :: ", error);
      yield put({ type: ThemeActions.SET_THEME_FAILURE, payload: error });
      return false;
  }
}

export function* themeSaga() {
  yield takeLatest(ThemeActions.SET_THEME, themeSet);
}
