const {
  getItemFromLocalStorage,
  addItemToLocalStorage,
} = require("../../utils/localStorage");

function setThemeMode(themeName) {
  addItemToLocalStorage("Theme", themeName);
  document.documentElement.className = themeName;
}

function keepTheme() {
  if (getItemFromLocalStorage("Theme") === "light") {
    setThemeMode("light");
  } else setThemeMode("dark");
}

module.exports = {
  setThemeMode,
  keepTheme,
};
