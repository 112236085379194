import { put, takeLatest, call } from "redux-saga/effects";
import { GamesActions } from "../types";
import gamesApi from "../api";
import { openNotificationWithIcon, getError } from "../../../utils/helper";

function* startSession(action) {
  try {
    const response = yield call(gamesApi.getGames, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({ type: GamesActions.GET_GAMES_SUCCESS, payload: response?.data?.ResponseObject });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: GamesActions.GET_GAMES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* markGameFavourite(action) {
  try {
    const response = yield call(gamesApi.markGameFavourite, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
      // action?.payload?.Method === "AddToFavoriteList" ? 
      //     openNotificationWithIcon(
      //       "success",
      //       "Success",
      //       "Game added to favorite list"
      //     ) :   openNotificationWithIcon(
      //       "success",
      //       "Success",
      //       "Game removed favorite from list"
      //     )
        }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: GamesActions.MARK_GAME_FAVOURITE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: GamesActions.MARK_GAME_FAVOURITE_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}



function* getplayGames(action) {
  try {
    const response = yield call(gamesApi.playGame, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: GamesActions.PLAY_NOW_GAME_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: GamesActions.PLAY_NOW_GAME_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* gamesSaga() {
  yield takeLatest(GamesActions.GET_GAMES, startSession);
  yield takeLatest(GamesActions.MARK_GAME_FAVOURITE, markGameFavourite);
  yield takeLatest(GamesActions.PLAY_NOW_GAME, getplayGames);

}
