import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Images } from "../../../Images";
import CasherNav from "../context/context";
import { navEl } from "../lib/constants";
import { useTranslation } from "react-i18next";


const NavBar = () => {
  const { t } = useTranslation();
  const { navURL, setNavURL } = useContext(CasherNav);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [show, setShow] = useState(false);
  const [navImage, setNavImage] = useState(Images.finances);
  const location = window.location.pathname;

  const nameToImage = (name) => {
    navEl.forEach((el) => {
      if (el.title === name) {
        setNavImage({ img: el.img, imgLight: el.imgLight });
      }
    });
  };

  useEffect(() => {
    nameToImage(navURL);
  }, [navURL]);

  useEffect(() => {
    setNavURL(
      location?.split("/")[3]?.charAt(0)?.toUpperCase() +
        location.split("/")[3]?.slice(1)
    ); // eslint-disable-next-line
  }, [location]);

  return (
    <div className="">
      <div className="cabinet-nav">
        {navEl.map((el, i) => (
          <NavLink exact activeClassName="active" className="cabinet-nav-link" to={el.route} key={el.title}>
            <div className="cabinet-nav-link-icon">
              <img data-e2e={el.title}
                className=""
                width="30"
                height="30"
                loading="lazy"
                src={themeMode === "dark" ? el.img : el.imgLight}
                alt={el.title}
              />
            </div>
            {t(el.title)}
          </NavLink>
        ))}
      </div>

      <div className="cabinet-nav-m" onClick={() => setShow((prev) => !prev)}>
        <div
          className={`${
            show ? "active" : ""
          } cabinet-nav-m-selected cabinet-nav-link`}
        >
          <div className="cabinet-nav-link-icon">
            <img
              width="30"
              height="30"
              src={themeMode === "dark" ? navImage.img : navImage.imgLight}
              alt="icon"
            />
          </div>
          {t(navURL)}
          <img
            className="cabinet-nav-link-chevron"
            width="13"
            height="13"
            src={
              themeMode === "light"
                ? Images.chevronYellowLight
                : Images.chevronYellow
            }
            alt="chevron icon"
          />
        </div>
        <div className={`${show ? "active" : ""} cabinet-nav-list`}>
          {navEl?.map((el, i) => {
            if (navURL && navURL.toLowerCase() !== el.title.toLowerCase()) {
              return (
                <NavLink
                  exact
                  activeClassName="active"
                  className="cabinet-nav-link"
                  to={el.route}
                  key={`${el.title}-m`}
                >
                  <div className="cabinet-nav-link-icon">
                    <img
                      width="30"
                      height="30"
                      loading="lazy"
                      src={themeMode === "dark" ? el.img : el.imgLight}
                      alt={el.title}
                    />
                  </div>
                  {t(el.title)}
                </NavLink>
              );
            } else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
