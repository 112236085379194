import axios from "axios";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import browser from "browser-detect";

export async function fetchAccountTypes(PaymentSystemId, setAccountTypes) {
  const Token = getItemFromLocalStorage("Token");
  const ClientId = getItemFromLocalStorage("Id");

  const payload = {
    Loader: true,
    PartnerId: 43,
    TimeZone: 0,
    LanguageId: "en", // LanguageId
    ProductId: null,
    Method: "GetPaymentBanks",
    Controller: "Document",
    CategoryId: null,
    PageIndex: 0,
    PageSize: 100,
    ProviderIds: [],
    Index: null,
    ActivationKey: null,
    MobileNumber: null,
    Email: null,
    Code: null,
    Type: null,
    ClientId: ClientId,
    PaymentInfo: null,
    RequestData: PaymentSystemId,
    Token: Token,
  };
  if (Token) {
    axios
      .post(`https://websitewebapi.oceanbet.io/43/api/Main/ApiRequest`, payload)
      .then((res) => {
        let response = res.data.ResponseObject;
        response = response.map((x) => ({ value: x.BankCode, label: x.BankName }));
        setAccountTypes(response);
      })
      .catch((err) => console.log(err));
  }
}

export function fetchResponseUrl(ResponseObject, ContentType, setTransactionUrl, setOpenModel) {
  const Token = getItemFromLocalStorage("Token");

  if (Token) {
    const hostName = window?.location?.host;
    // console.log("hostName ====", hostName);
    let newurl = hostName.replace("www.", "");
    // console.log("newurl ====", newurl);
    let a = ResponseObject?.replace(newurl, "oceanbet.io");
    // console.log("a ===", a);
    // console.log("ContentType", ContentType);
    setTransactionUrl(a);
    if (ContentType === 1) {
      window.location.href = a; // Open transaction URL in a current tab
    } else if (ContentType === 2) {
      a && window && window.open(a, "_blank"); // Open transaction URL in a new tab
    } else if (ContentType === 5) {
      a && window && window.open(a, "_blank", "width=600,height=400"); // Open transaction URL in a new window
    } else {
      a && setOpenModel(true); // Open transaction URL in iframe
    }
  }
}

export async function saveActivityDepositForm(PaymentSystemId, CurrencyId, amount) {
  const browserInfo = browser();
  let deviceIp = await getDeviceIP();
  saveActivity({
    EventPage: "Cashier",
    EventType: "Deposit_Confirm",
    attribute1: getItemFromLocalStorage("UserName") || "",
    attribute2: PaymentSystemId,
    attribute3: CurrencyId,
    attribute4: amount,
    attribute5: navigator.language,
    device_type: browserInfo.mobile ? "Mobile" : "Desktop",
    browser_type: browserInfo.name,
    browser_version: browserInfo.version,
    language: navigator.language,
    ip_address: deviceIp,
  });
}
