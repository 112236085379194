import { headeractions } from "../types";

export const getClientBalance = (payload) => {
  return {
    type: headeractions.GET_CLIENT_BALANCE,
    payload: payload,
  };
};

export const getClientBalanceSuccess = (payload) => {
  return { type: headeractions.GET_CLIENT_BALANCE_SUCCESS, payload: payload };
};

export const getClientBalanceError = (payload) => {
  return { type: headeractions.GET_CLIENT_BALANCE_ERROR, payload: payload };
};

export const getGames = (payload) => {
  return {
    type: headeractions.GET_SEARCHBAR_GAMES,
    payload: payload,
  };
};
export const getGamesSuccess = (payload) => {
  return { type: headeractions.GET_SEARCHBAR_GAMES_SUCCESS, payload: payload };
};

export const getGamesError = (payload) => {
  return { type: headeractions.GET_SEARCHBAR_GAMES_ERROR, payload: payload };
};
