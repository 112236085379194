import React from "react";
import BasicLayout from "../../../RivieraBetBasicLayout";

const PrivacyPolicy = () => {
  return (
    <BasicLayout>
      <div className="content-px content-py uw-content legal">
        <div className="layout-title">Privacy & Management of Personal Data</div>
        <div className="legal-block">
          RivieraBet is operated by R. Bostock Enterprises B.V. (reg. nr. 159489), Managing Director Kurason Trust, Schottegatweg Oost 10 Unit 1-9 Bon Bini Business Center, Curaçao, and regulated under gaming license nr. 1668/JAZ issued to Curaçao eGaming, Authorized and Regulated by the Government of Curacao, (hereinafter – “We”) and is committed to the protection of players' personal data. This Privacy & Management of Personal Data Policy (“Policy”) describes what information about is collected and why, how it is processed and what rights you have in connection with this. The processing of players' data complies with all applicable data protection regulations including the European General Data Protection Regulation (GDPR). Personal data means any information about an individual from which that person can be identified. In terms of regulation, we act as a data controller meaning that we determine the purposes for which and the means by which personal data is processed. <br />
          <br />
          <br />
          <p>
            <span>Your Consent</span>
          </p>
          <br />
          By using the Website, you give your consent to us to collect, use and process your personal data as set out in this Policy. Not providing personal data may mean that we are unable to provide services to you.
          <br />
          <br />
          <p>
            <span>What Personal Data Is Collected And When</span>
          </p>
          <br />
          When you use the Website, we may collect the following personal data:
          <br />
          <br />
          • Identity data: name and surname, date of birth, gender etc.
          <br />
          • Contact data: address, email, phone, other means of contact etc.
          <br />
          • Financial data: payment cards, bank account details, source of wealth and source of income etc.
          <br />
          • Account and usage data: username, your gambling preferences and habits, information on how you use the Website etc.
          <br />
          • Payment and transactions data: deposits and withdrawals, any other financial transactions occurred while you are using the Website etc.
          <br />
          • Technical and other data: IP address, login data, browser, time zone and language settings, device etc.
          <br />
          • We may collect different types of your personal data on a different stages of your interaction with the Website, for example when you visit Website, create an Account or making bets after logging in.
          <br />
          • We may receive personal data from you in different forms, namely via:
          <br />
          • Direct contact with you: we collect identity and contact data when you create an Account and provide initial information, make deposits and withdrawals, play different games or make bets on sport events.
          <br />
          • Third parties: we may receive your personal data from third parties like payment providers or data brokers.
          <br />
          • Public sources: we may receive your personal data from social medias or news aggregators.
          <br />
          • Technical data: some personal data is automatically given to us when you use the Website.
          <br />
          <br />
          <p>
            <span>How and Why We Use Your Personal Data</span>
          </p>
          <br />
          The applicable law grounds based on which we can collect and use your personal data. These allowed bases are as follows:
          <br />
          <br />
          • We collect and use your personal data because it is necessary to perform the contract you concluded with us (by your acceptance of our Terms and Conditions).
          <br />
          • We collect and use your personal data when we have to do this to comply with the legal and regulatory obligations.
          <br />
          • We collect and use your personal data when it is determined by our legitimate interest.
          <br />
          • Also, we can collect and use your personal data upon receiving your consent.
          <br />
          <br />
          Below we detail for which exactly purposes we collect your personal data:
          <br />
          <br />
          • To enable your registration on the Website so that you can use the Website’s features and services;
          <br />
          • To maintain ongoing relations with you;
          <br />
          • To process your transactions on the Website, like deposits, bets and withdrawals;
          <br />
          • To comply with relevant regulations regarding registration, for example completing age verification and other KYC checks;
          <br />
          • To carry out internal risk management procedures and monitoring transactions for the purpose of preventing fraud, irregular betting, money laundering and cheating;
          <br />
          • To keep you informed about future events, offer, promotions and bonuses;
          <br />
          • To prepare and show you customized marketing and advertising content;
          <br />
          • To administer and protect our business and the Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data);
          <br />
          • To use data analytics to improve our website, products/services, marketing, customer relationships and experiences;
          <br />• Any other purpose which is necessary for the performance of our contractual obligations or legitimate interest.
          <br />
          <br />
          <p>
            <span>Sharing Your Personal Data</span>
          </p>
          <br />
          We treat your personal data as strictly confidential. However, you have to understand that in certain cases we may disclose personal data to some third parties, namely:
          <br />
          <br />
          • Affiliates: all direct or indirect subsidiaries of RivieraBet ,i ts joint venture partners, and their related companies. We may share your Personal data with affiliates for set-up, verification and management of your account, for marketing research and data analysis, to conduct relevant risk management and comply with legal and regulatory obligations.
          <br />
          • Service Providers: we may share your personal data with third-party service providers when services you use on the Website are provided through the third-party provider, such as casino games, live games sports. This also include payment service providers, without which it would be impossible to process your deposits or withdrawals. Also, we use data analysis, research and surveys to identify and satisfy customer needs. To help with this, we may also transfer some of your personal data to relevant marketing agencies. To comply with our regulatory and legal obligations, we may have to share your personal data with professional advisers including lawyers, bankers, auditors and insurers based who provide consultancy, banking, legal, insurance and accounting services.
          <br />
          • Licensing and other authorities: In order to comply with legal obligations, we may be required to transfer your data (registration details, identification documentation, financial transactions, gaming transactions and general information about accounts) to regulatory and enforcement authorities.
          <br />
          <br />
          We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
          <br />
          <br />
          Some of the third parties to whom we may disclose your personal data are located outside of European Union. However, we will share your personal data with them only if the recipient guarantees a standard of data protection comparable to European standards (e.g. by agreeing to approved contractual clauses, intragroup agreements or other measures designed to ensure that the recipients of personal data protect them).
          <br />
          <br />
          <p>
            <span>Where We Keep Your Personal Data and For How Long</span>
          </p>
          <br />
          We keep your personal data in the data centers within the Europe.
          <br />
          <br />
          We have implemented appropriate technical and organizational controls to protect your data against unauthorized access, processing, loss, modification or disclosure. All data is processed in an automated manner. In case of need, only our authorized employees or employees of third parties contractually bound to compliance with the principles of data protection, have access to your personal data. We monitors the trustworthiness and reliability of all employees.
          <br />
          <br />
          We use the latest technology to ensure the safe storage of all personal data of the Website players. We use 128-bit SSL, a technology that is chosen by many legal and financial organizations and guarantees 100% security for all transactions made through the Website.
          <br />
          <br />
          We will store your personal data for the relevant retention period as required by applicable legislation. Thus, such information will be processed until you delete your account on the Website and further within the reasonable period, in accordance with the terms of anti-money laundering and other requirements for gambling operators.
          <br />
          <br />
          <p>
            <span>Your Rights And How To Exercise Them</span>
          </p>
          <br />
          With respect to your personal data, you have the following rights:
          <br />
          <br />
          Right to be informed about your personal data: By this Policy, we highlight the most significant issues concerning the security of your personal data to keep you informed.
          <br />
          <br />
          Right to access. You have the right to access your personal data (Data Subject Access Request).
          <br />
          <br />
          Right to fix incorrect data. You have a right to change inaccurate personal data in our possession.
          <br />
          <br />
          Right to erasure. You have the right to request that any personal information that we hold about you is erased once it is no longer required for the purposes for which it was collected. However, we are not bound to comply with such a request if processing of your personal data is necessary to exercise the right of freedom of expression and information, comply with a legal obligation in the public interest, exercise official authority and/or establish, exercise or defend legal claims.
          <br />
          <br />
          Right to restrict processing. You have the right to request the processing’s suspension of your personal data, for example, if you want us to establish the data's accuracy.
          <br />
          <br />
          Right to data transfers. You are entitled to transfer your personal data in our possession to any other data Controller. You also have a right to receive a report on your personal data from us in a structured, commonly used and machine-readable format.
          <br />
          <br />
          Rights related to automated decision-making. If a fully automated decision is made on your account, you have the right to request that this decision is reviewed by our team.
          <br />
          <br />
          Right to object. You have the right to object to the processing of your personal data. However, this right is not absolute and where legitimate grounds to use your information outweigh your interests and rights, we may continue processing.
          <br />
          <br />
          If you have any questions about this Policy, including any requests to exercise your legal rights as described above, please contact us at support@rivierabet.com We will process your request within 30 working days.
          <br />
          <br />
          <p>
            <span>Third Party Content</span>
          </p>
          <br />
          The Website may include links to third-party websites or applications. Clicking on links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy policies. When you leave our Website, please make sure that you have read privacy policies and statements on the external websites.
          <br />
          <br />
          We do not control nor assume any responsibility for any third-party websites that contain illegal or defamatory content and that might include a link to the Website.
          <br />
          <br />
          <p>
            <span>Cookies</span>
          </p>
          <br />
          Types and purpose of cookies. We use cookies and similar technologies (collectively referred to as cookies) to distinguish you from other users of the Website and provide you with the best possible service and experience. This process is done automatically and the information we may collect is described in detail above in this Policy. Broadly, our use of cookies can be categorized into cookies necessary to maintain the functionality and security of the Website, cookies that allow us to conduct analytics and improve the Website, cookies that allow us to customize and tailor the Website to your needs, and cookies that allow us to advertise the Website. In addition to these cookies, some third parties, including advertising services, social media networks, and support software utilized by us, may also use cookies over which we have no control. These cookies would likely serve targeting and analytical and performance functions.
          <br />
          <br />
          Opting Out and Blocking Cookies. If you do not wish to have information about you from your use of the Website and other websites used for such purposes, you can also block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including those that are necessary cookies for the use of the Website) you may not be able to access all or parts of the Website.
          <br />
          <br />
          <p>
            <span>Other</span>
          </p>
          <br />
          Anonymization and Aggregation of Data. We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, internal research and data analysis, improving the Website, and developing new products and features.
          <br />
          <br />
          Changes to this Policy. We may change this Policy. If we make any changes in the way we collect or use your information, we will let you know by posting an announcement on our Website and emailing you. If you are subject to the GDPR, you will be prompted to consent to the changes of our Policy. If you use our Website after any changes have been made, you agree to the revised Privacy Policy.
        </div>
      </div>
    </BasicLayout>
  );
};

export default PrivacyPolicy;
