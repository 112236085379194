import { CasinoActions } from "../types";

export const casino = (payload) => {
  return {
    type: CasinoActions.GET_CASINO_GAMES,
    payload: payload,
  };
};
export const casinoSuccess = (payload) => {
  return { type: CasinoActions.GET_CASINO_GAMES_SUCCESS, payload: payload };
};

export const casinoError = (payload) => {
  return { type: CasinoActions.GET_CASINO_GAMES_ERROR, payload: payload };
};
