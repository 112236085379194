import { Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

const RightResponsible = () => {
  const { Option } = Select;
	
  const dateFormatList = ["DD/MM/YYYY"];
  const { t } = useTranslation();

  return (
    <div className="cabinetForm cabinet-block">
      <div className="cabinetForm-block">
        <div className="cabinetForm-title">{t("Self-Exclusion")}</div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Type of Exclusion")}</p>
          <Select
            className="theme-input no-border"
            placeholder={t("Select type of exclusion")}
          >
            <Option value="USDT">{t("Temporary")}</Option>
          </Select>
        </div>

        <div className="inputGroup">
          <p className="inputGroup-label">{t("Until Date")}</p>
          <DatePicker
            suffixIcon=""
            className="no-border"
            placeholder={t("Select until date")}
            allowClear={false}
            onChange={(date, dateString) => console.log(date, dateString)}
            format={dateFormatList}
          />
        </div>
        <div className="cabinetForm-btn no-mobile theme-btn full-width sqr-btn">
          {t("Confirm")}
        </div>
      </div>
    </div>
  );
};

export default RightResponsible;
