const Praxis = "/images/payments/praxis.png";
const XcoinsPayCrypto = "/images/payments/XcoinsPayCrypto.svg";
const XcoinsPayCard = "/images/payments/XcoinsPayCard.svg";
const PaymentCrytopay = "/images/payments/cryptopay.png";
const PaymentFiat = "/images/payments/fiat.png";
const PaymentInterac = "/images/payments/Interac.png";
const PaymentLuxon = "/images/payments/Luxonpay.png";
const PaymentNowpay = "/images/payments/now-payments.png";
const PaymentVisa = "/images/payments/Visamaster.png";
const bookOfDeadBannerDesktop = "/images/banner-r/book-of-dead-desktop.webp";
const bookOfDeadBannerMobile = "/images/banner-r/book-of-dead-mobile.webp";
const bigBassBannerDesktop = "/images/banner-r/big-bass-desktop.webp";
const bigBassBannerMobile = "/images/banner-r/big-bass-mobile.webp";
const blingedBannerDesktop = "/images/banner-r/blinged-desktop.webp";
const blingedBannerMobile = "/images/banner-r/blinged-mobile.webp";
const hammerBannerDesktop = "/images/banner-r/hammer-desktop.webp";
const hammerBannerMobile = "/images/banner-r/hammer-mobile.webp";
const pimpedBannerDesktop = "/images/banner-r/pimped-desktop.webp";
const pimpedBannerMobile = "/images/banner-r/pimped-mobile.webp";
const whiteKingBannerDesktop = "/images/banner-r/white-king-desktop.webp";
const whiteKingBannerMobile = "/images/banner-r/white-king-mobile.webp";
const thunderBannerDesktop = "/images/banner-r/thunder-desktop.webp";
const thunderBannerMobile = "/images/banner-r/thunder-mobile.webp";
const wolfGoldBannerDesktop = "/images/banner-r/wolf-gold-desktop.webp";
const wolfGoldBannerMobile = "/images/banner-r/wolf-gold-mobile.webp";

const headerLogo = "/images/header/header-logo.svg";
const pragmaticLive = "/images/livecasino-r/live-pragmatic.webp";
const pragmaticLiveNoLogo = "/images/livecasino-r/live-pragmatic-no-logo.webp";
const ezugiLive = "/images/livecasino-r/live-ezugi.webp";
const ezugiLiveNoLogo = "/images/livecasino-r/live-ezugi-no-logo.webp";
const evolutionLive = "/images/livecasino-r/live-evolution.webp";
const evolutionLiveNoLogo = "/images/livecasino-r/live-evolution-no-logo.webp";
const vivoGamingLive = "/images/livecasino-r/live-vivo.webp";
const vivoGamingLiveNoLogo = "/images/livecasino-r/live-vivo-no-logo.webp";
const crazyTimeBanner = "/images/banner-r/crazytime.webp";
const forgotEmailIcon = "/images/modal/forgot-email.svg";
const codeIcon = "/images/modal/code.svg";
const SugarRush = "/images/sweet-r/sugar-rush.webp";
const SugarPop = "/images/sweet-r/sugar-pop.gif";
const SugarDrop = "/images/sweet-r/sugar-drop.gif";
const DragonWatch = "/images/dragon-r/dragon-watch.webp";
const DragonKings = "/images/dragon-r/dragon-kings.gif";
const DragonHoldAndSpin = "/images/dragon-r/dragon-hold-and-spin.gif";
const LeprechaunMagic = "/images/magic-r/magic-leprechauns.webp";
const MagicCharm = "/images/magic-r/magic-charm.webp";
const MagicMerlin = "/images/magic-r/magic-merlin.webp";
const MagicCauldron = "/images/magic-r/magic-cauldron.webp";
const monopolyLive = "/images/livecasino-r/live-monopoly.webp";
const sweetBonanzaLive = "/images/livecasino-r/live-sweetbonanza.webp";
const teenPattiLive = "/images/livecasino-r/live-teenpatti.webp";
const lightRouletteLive = "/images/livecasino-r/live-lightningroulette.webp";
const crazyTimeLive = "/images/livecasino-r/live-crazytime.webp";
const boomCityLive = "/images/livecasino-r/live-boomcity.webp";
const faqOpenIcon = "/images/deco/faq-open-icon.svg";
const faqOpenIconLight = "/images/deco/faq-open-icon-light.svg";
const nowpayWhite = "/images/paymethods-r/nowpay.svg";
const luxonWhite = "/images/paymethods-r/luxon.svg";
const cryptonPayWhite = "/images/paymethods-r/cryptonpay.svg";
const netellerWhite = "/images/paymethods-r/neteller.svg";
const skrillWhite = "/images/paymethods-r/skrill.svg";
const cryptoWhite = "/images/paymethods-r/crypto.svg";
const optimumWhite = "/images/paymethods-r/optimumway.svg";
const interacWhite = "/images/paymethods-r/interac.svg";
const selectedIcon = "/images/paymethods-r/selected-icon.svg";
const financesLight = "/images/personal/finances-light.svg";
const historyLight = "/images/personal/history-light.svg";
const bonusesLight = "/images/personal/bonuses-light.svg";
const profileLight = "/images/personal/profile-light.svg";
const ticketLight = "/images/personal/ticket-light.svg";
const balanceLight = "/images/personal/balance-light.svg";
const questionMark = "/images/footer/support/questionMark.svg";
const blackPhoneIcon = "/images/footer/support/blackPhoneIcon.svg";
const blackEmailIcon = "/images/footer/support/blackEmailIcon.svg";
const blackAddressIcon = "/images/footer/support/blackAddressIcon.svg";

//---
const Games41 = "/images/sidebar/games4-1.svg";
const Games41Light = "/images/sidebar/games4-1-light.svg";
const chevronYellowLight = "/images/deco/chevron-yellow-light.svg";
const starLight = "/images/casino/star-light.svg";
const starsLight = "/images/casino/stars-light.svg";
const cherryLight = "/images/casino/cherrynew-light.svg";
const diceLight = "/images/casino/dicenew-light.svg";
const heartLight = "/images/casino/heart-light.svg";
const modalLogo = "/images/modal/logo.svg";
const countryIcon = "/images/modal/country.svg";
const phoneIcon = "/images/modal/phone.svg";
const documentNumberIcon = "/images/modal/document-number.svg";
const textureBackground = "/images/deco/texture.webp";
const chevronYellow = "/images/deco/chevron-yellow.svg";
const sliderArrowLeft = "/images/deco/slider-arrow-left.svg";
const sliderArrowLeftLight = "/images/deco/slider-arrow-left-light.svg";

const darkBackground = "/images/deco/bg-dark.webp"; //delete
const lightBackground = "/images/deco/bg-light.webp"; //delete

const searchIcon = "/images/header/search.svg";
const fastLinkIcon = "/images/header/link.svg";
const supportIcon = "/images/header/support.svg";
const emailModelIcon = "/images/header/email.svg";
const navProfileIcon = "/images/header/profile.svg";
const logoutIcon = "/images/header/logout.svg";
const lightThemeIcon = "/images/header/light-theme-icon.svg";
const darkThemeIcon = "/images/header/dark-theme-icon.svg";
const timeIcon = "/images/header/time.svg";
const navProfileIconLight = "/images/header/profile-light.svg";
const userIconLight = "/images/sidebar/userIconLight.svg";
const depositIcon = "/images/sidebar/deposit-icon.svg";
const withdrawIcon = "/images/sidebar/withdraw-icon.svg";
const bankIcon = "/images/sidebar/bank-icon.svg";
const historyIcon = "/images/sidebar/history-icon.svg";
const settingsIcon = "/images/sidebar/settings-icon.svg";
const ticketsIcon = "/images/sidebar/tickets-icon.svg";
const closeIcon = "/images/modal/closeIcon.svg";
const chevronRightIcon = "/images/icons/chevron-right.svg";
const sideBarLogo = "/images/sidebar/sidebar-logo.svg";
const en = "/images/languages/en.svg";
const de = "/images/languages/de.svg";
const da = "/images/languages/da.svg";
const fr = "/images/languages/fr.svg";
const es = "/images/language/es.png";
const it = "/images/language/it.png";
const nn = "/images/language/nn.png";
const ru = "/images/language/ru.png";
const zh = "/images/language/zh.png";
const pt = "/images/language/pt.png";
const ptBR = "/images/language/ptBR.svg";
const gameGlowDeco = "/images/deco/game-glow.svg";
const emailIcon = "/images/modal/email.svg";
const currency = "/images/modal/currency.svg";
const coupon = "/images/modal/coupon.svg";
const calendar = "/images/modal/calendar.svg";
const userIconModal = "/images/modal/user.svg";
const lockIcon = "/images/modal/lock.svg";
const passwordIcon = "/images/modal/password.svg";
const modalBackground = "/images/modal/background.webp"; //delete

//ALL GAMES
//-----------Megaways
const BigBadWolf = "/images/megaways-r/Big-Bad-Wolf-Megaways.webp";
const DragonMatch = "/images/megaways-r/Dragon-Match-Megaways.webp";
const AztecGold = "/images/megaways-r/Aztec-Gold-Extra-Gold-Megaways.webp";
const Fruitshop = "/images/megaways-r/Fruitshop-Megaways.webp";
const Morgana = "/images/megaways-r/Morgana-Megaways.webp";
const Moriarty = "/images/megaways-r/Moriarty-Megaways.webp";
const Royale = "/images/megaways-r/Royale-with-Cheese-Megaways.webp";
const Scrooge = "/images/megaways-r/Scrooge-Megaways.webp";
const Starz = "/images/megaways-r/Starz-Megaways.gif";
const TyrantKing = "/images/megaways-r/Tyrant-King-Megaways.webp";
//-----------SweetTreat
const Sweets = "/images/sweet-r/Super-Sweets.gif";
const Alchemy = "/images/sweet-r/Sweet-Alchemy.gif";
const SweetBonanza = "/images/sweet-r/Sweet-Bonanza.webp";
const SweetieLand = "/images/sweet-r/Sweetie-Land.webp";
const SweetSugar = "/images/sweet-r/Sweet-Sugar.webp";
//--sweet treats 2
const SweetWin = "/images/sweet-r/Sweet-Win.webp";
//-----------TameDragon
const Dragon = "/images/dragon-r/Dragon.webp";
const DoubleDragons = "/images/dragon-r/Double-Dragons.webp";
//--twin Dragons
const DragonHorn = "/images/dragon-r/Dragon-Horn.gif";
const DragonShrine = "/images/dragon-r/Dragon-Shrine.webp";
const FloatingDragon = "/images/dragon-r/Floating-Dragon.webp";
const LuckyDragon = "/images/dragon-r/Lucky-Dragon.webp";
//-----------MagicMoments
const JadeMagician = "/images/magic-r/Jade-Magician.webp";
const HulaMagic = "/images/magic-r/Hula-Magic.gif";
const MagicForest = "/images/magic-r/Magic-Forest.webp";
const MagiciansSecrets = "/images/magic-r/Magicians-Secrets.webp";
const Magicious = "/images/magic-r/Magicious.webp";
const MagicRings = "/images/magic-r/Magic-Of-The-Ring.webp";
//-----------BookClub
const BookDuat = "/images/home-r/Book-of-Duat.gif";
const BookDead = "/images/home-r/Book-of-Dead.gif";
const BookDarkness = "/images/home-r/Book-of-Darkness.gif";
const BookAztec = "/images/home-r/Book-of-Aztec-Select.gif";
const BookSkulls = "/images/home-r/Book-of-Skulls.gif";
const BookImmortals = "/images/home-r/Book-of-Immortals.gif";
const BookFortune = "/images/home-r/Book-of-Fortune.gif";
const BookFruits = "/images/home-r/Book-of-Fruits-10.gif";
const BookKingdoms = "/images/home-r/Book-of-Kingdoms.gif";
const BookTombs = "/images/home-r/Book-of-Tombs.gif";
//-----------Top 10 games
const ChiliPop = "/images/home-r/Chili-Pop.gif";
const InvisibleManGif = "/images/home-r/InvisibleMan.gif";
const HugoCarts = "/images/home-r/HugoCarts.gif";
const YetiGigablox = "/images/home-r/90k-Yeti-Gigablox.gif";

const Deadwood = "/images/home-r/Deadwood.gif";
const RazorShark = "/images/home-r/RazorShark.gif";
const JumboStampede = "/images/home-r/JumboStampede.gif";
const SweetBonanzagif = "/images/home-r/SweetBonanza.gif";
const EsqueletoExplosivo = "/images/home-r/Esqueleto-Explosivo.gif";
const ChaosCrew = "/images/home-r/Chaos-Crew.gif";
//-----
const userIcon = "/images/sidebar/userIcon.svg";
const casinoLink = "/images/sidebar/casino.svg";
const gamesLink = "/images/sidebar/games.svg";
const homeLink = "/images/sidebar/home.svg";
const inplayLink = "/images/sidebar/inplay.svg";
const livecasinoLink = "/images/sidebar/live-casino.svg";
const promoLink = "/images/sidebar/promotions.svg";
const sportLink = "/images/sidebar/sport.svg";
const mastercard = "/images/footer/mastercard.svg";
const visa = "/images/footer/visa.svg";
const neteller = "/images/footer/neteller.svg";
const paysafecard = "/images/footer/paysafecard.svg";
const skrill = "/images/footer/skrill.svg";
const paypal = "/images/footer/paypal.svg";
const age_limit = "/images/footer/age-limit.svg";
const downArrowGray = "/images/casher/downArrowGray.svg";
const safeBetterBgLight = "/images/home/safe-betterlight.svg";
const casino_2_bg = "/images/footer/footer-bg.svg";
const RivieraBetfooterLogo = "/images/sidebar/rivierabetfooterlogo.svg";

//----

const document = "/images/register/document.svg";
const RivieraBetLogo = "/images/sidebar/rivierabetlogo.svg";
const discord = "/images/footer/discord.svg";
const gmail = "/images/footer/gmail.svg";
const telegram = "/images/footer/telegram.svg";
const twitter = "/images/footer/twitter.svg";
const cherry = "/images/casino/cherrynew.svg";
const star = "/images/casino/starnew.svg";
const stars = "/images/casino/starsnew.svg";
const dice = "/images/casino/dicenew.svg";
const pokerCards = "/images/casino/poker-cards.svg";
const heart = "/images/casino/heartnew.svg";
const Games4 = "/images/sidebar/games4.svg";
const CasinoBanner = "/images/casino/casinobanner.svg";

// live casino
const pragmatic = "/images/livecasino/pragmatic.svg";
const evolution = "/images/livecasino/evolution.svg";
const ezugi = "/images/livecasino/ezugi.svg";
const vivoGaming = "/images/livecasino/vivoGaming.svg";
const playIcon = "/images/livecasino/playIcon.svg";

// Casher
const finances = "/images/casher/nav/finances.svg";
const history = "/images/casher/nav/history.svg";
const bonuses = "/images/casher/nav/bonuses.svg";
const profile = "/images/casher/nav/profile.svg";
const ticket = "/images/casher/nav/ticket.svg";
const balance = "/images/casher/nav/balance.svg";
const info = "/images/casher/info.svg";
const noAccounts = "/images/casher/noAccounts.svg";
const chooseFile = "/images/casher/chooseFile.svg";
const shape = "/images/casher/shape.svg";

const luxon = "/images/casher/paymethods/luxon.svg";
const luxonlight = "/images/casher/paymethods/luxonlight.svg";
const skrillCalight = "/images/casher/paymethods/skrillCalight.svg";
const netellerCalight = "/images/casher/paymethods/netellerCalight.svg";
const cryptolight = "/images/casher/paymethods/cryptolight.svg";
const cryptonPay = "/images/casher/paymethods/cryptonPay.svg";
const crypto = "/images/casher/paymethods/crypto.svg";
const netellerCa = "/images/casher/paymethods/netellerCa.svg";
const skrillCa = "/images/casher/paymethods/skrillCa.svg";
const american = "/images/casher/paymethods/american.svg";
const master = "/images/casher/paymethods/master.svg";
const nowPay = "/images/casher/paymethods/nowPay.svg";
const visaCa = "/images/casher/paymethods/visaCa.svg";
const luxonIn = "/images/casher/paymethods/inactive/luxonIn.svg";
const cryptonPayIn = "/images/casher/paymethods/inactive/cryptonPayIn.svg";
const cryptoIn = "/images/casher/paymethods/inactive/cryptoIn.svg";
const netellerCaIn = "/images/casher/paymethods/inactive/netellerCaIn.svg";
const americanIn = "/images/casher/paymethods/inactive/americanIn.svg";
const masterIn = "/images/casher/paymethods/inactive/masterIn.svg";
const nowPayIn = "/images/casher/paymethods/inactive/nowPayIn.svg";
const visaCaIn = "/images/casher/paymethods/inactive/visaCaIn.svg";
const skrillCaIn = "/images/casher/paymethods/inactive/skrillCaIn.svg";
const edit = "/images/casher/edit.svg";
const resize = "/images/casino/resize.svg";
const noTicket = "/images/casher/noTicket.svg";
const pdfIcon = "/images/sidebar/pdf-icon.svg";

export const Images = {
  crazyTimeBanner,
  resize,
  luxon,
  luxonlight,
  skrillCalight,
  netellerCalight,
  cryptolight,
  cryptonPay,
  crypto,
  netellerCa,
  skrillCa,
  american,
  master,
  nowPay,
  visaCa,
  luxonIn,
  cryptonPayIn,
  cryptoIn,
  netellerCaIn,
  americanIn,
  masterIn,
  nowPayIn,
  visaCaIn,
  skrillCaIn,
  edit,
  chooseFile,
  noAccounts,
  pragmatic,
  evolution,
  ezugi,
  vivoGaming,
  playIcon,
  RivieraBetfooterLogo,
  RivieraBetLogo,
  BookAztec,
  BookDarkness,
  BookDead,
  BookDuat,
  BookFortune,
  BookFruits,
  BookImmortals,
  BookKingdoms,
  BookSkulls,
  BookTombs,
  YetiGigablox,
  ChaosCrew,
  ChiliPop,
  Deadwood,
  EsqueletoExplosivo,
  MagicForest,
  MagiciansSecrets,
  Magicious,
  TyrantKing,
  SweetieLand,
  DragonShrine,
  LuckyDragon,
  HugoCarts,
  InvisibleManGif,
  JumboStampede,
  RazorShark,
  SweetBonanzagif,
  AztecGold,
  gameGlowDeco,
  sliderArrowLeft,
  sliderArrowLeftLight,
  BigBadWolf,
  DragonMatch,
  Fruitshop,
  Morgana,
  Moriarty,
  Royale,
  Scrooge,
  Starz,
  Sweets,
  Alchemy,
  SweetBonanza,
  SweetSugar,
  SweetWin,
  Dragon,
  DoubleDragons,
  DragonHorn,
  FloatingDragon,
  HulaMagic,
  JadeMagician,
  MagicRings,
  age_limit,
  casino_2_bg,
  safeBetterBgLight,
  discord,
  gmail,
  mastercard,
  neteller,
  paypal,
  paysafecard,
  skrill,
  telegram,
  twitter,
  visa,
  cherry,
  pokerCards,
  star,
  stars,
  dice,
  heart,
  Games4,
  CasinoBanner,
  finances,
  history,
  bonuses,
  profile,
  ticket,
  balance,
  info,
  modalBackground,
  modalLogo,
  closeIcon,
  emailIcon,
  passwordIcon,
  lockIcon,
  userIconModal,
  calendar,
  document,
  currency,
  coupon,
  searchIcon,
  fastLinkIcon,
  supportIcon,
  emailModelIcon,
  navProfileIcon,
  logoutIcon,
  lightThemeIcon,
  darkThemeIcon,
  timeIcon,
  en,
  de,
  da,
  fr,
  es,
  it,
  nn,
  ru,
  zh,
  pt,
  ptBR,
  chevronRightIcon,
  sideBarLogo,
  userIcon,
  casinoLink,
  gamesLink,
  homeLink,
  inplayLink,
  livecasinoLink,
  promoLink,
  sportLink,
  downArrowGray,
  navProfileIconLight,
  userIconLight,
  depositIcon,
  withdrawIcon,
  bankIcon,
  historyIcon,
  settingsIcon,
  ticketsIcon,
  darkBackground,
  lightBackground,
  textureBackground,
  countryIcon,
  phoneIcon,
  documentNumberIcon,
  chevronYellow,
  Games41,
  chevronYellowLight,
  starLight,
  cherryLight,
  diceLight,
  heartLight,
  starsLight,
  Games41Light,
  faqOpenIcon,
  questionMark,
  blackPhoneIcon,
  blackEmailIcon,
  blackAddressIcon,
  faqOpenIconLight,
  nowpayWhite,
  luxonWhite,
  cryptonPayWhite,
  netellerWhite,
  skrillWhite,
  interacWhite,
  cryptoWhite,
  selectedIcon,
  optimumWhite,
  shape,
  financesLight,
  historyLight,
  bonusesLight,
  profileLight,
  ticketLight,
  balanceLight,
  noTicket,
  forgotEmailIcon,
  codeIcon,
  SugarRush,
  SugarPop,
  SugarDrop,
  DragonWatch,
  DragonKings,
  DragonHoldAndSpin,
  LeprechaunMagic,
  MagicCharm,
  MagicMerlin,
  MagicCauldron,
  monopolyLive,
  sweetBonanzaLive,
  teenPattiLive,
  lightRouletteLive,
  crazyTimeLive,
  boomCityLive,
  pragmaticLive,
  evolutionLive,
  ezugiLive,
  vivoGamingLive,
  headerLogo,
  pragmaticLiveNoLogo,
  ezugiLiveNoLogo,
  evolutionLiveNoLogo,
  vivoGamingLiveNoLogo,
  bookOfDeadBannerDesktop,
  bookOfDeadBannerMobile,
  bigBassBannerDesktop,
  bigBassBannerMobile,
  blingedBannerDesktop,
  blingedBannerMobile,
  hammerBannerDesktop,
  hammerBannerMobile,
  pimpedBannerDesktop,
  pimpedBannerMobile,
  whiteKingBannerDesktop,
  whiteKingBannerMobile,
  thunderBannerDesktop,
  thunderBannerMobile,
  wolfGoldBannerDesktop,
  wolfGoldBannerMobile,
  PaymentCrytopay,
  PaymentFiat,
  PaymentInterac,
  PaymentLuxon,
  PaymentNowpay,
  PaymentVisa,
  pdfIcon,
  Praxis,
  XcoinsPayCrypto,
  XcoinsPayCard,
};
