import { put, takeLatest, call } from "redux-saga/effects";
import { liveCasinoActions } from "../types";
import liveCasinoApi from "../api";
import { openNotificationWithIcon, getError } from "../../../utils/helper";

function* getProductURL(action) {
  try {
    const response = yield call(liveCasinoApi.getProductURL, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: liveCasinoActions.GET_PRODUCT_URL_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getProductURL saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: liveCasinoActions.GET_PRODUCT_URL_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* liveCasinoSaga() {
  yield takeLatest(liveCasinoActions.GET_PRODUCT_URL, getProductURL);
}
