import { useContext } from "react";
import CasherNav from "../../context/context";
import DepositForm from "./forms/DepositForm";
import WithdrawForm from "./forms/WithdrawForm";

const FormSection = () => {
  const { finUrl } = useContext(CasherNav);

  const main = () => {
    if (finUrl === "Deposit") return <DepositForm />;
    if (finUrl === "Withdraw") return <WithdrawForm />;
  };

  return <div className="">{main()}</div>;
};

export default FormSection;
