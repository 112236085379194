import { CasherActions } from "../types";

export const withdraw = (payload) => {
  return { type: CasherActions.WITHDRAW, payload: payload };
};

export const withdrawSuccess = (payload) => {
  return { type: CasherActions.WITHDRAW_SUCCESS, payload: payload };
};

export const withdrawError = (payload) => {
  return {
    type: CasherActions.WITHDRAW_ERROR,
    payload: payload,
  };
};
export const selectedCard = (payload) => {
  return {
    type: CasherActions.SELECTED_CARD,
    payload: payload,
  };
};

export const deposit = (payload) => {
  return { type: CasherActions.DEPOSIT, payload: payload };
};

export const depositSuccess = (payload) => {
  return { type: CasherActions.DEPOSIT_SUCCESS, payload: payload };
};

export const depositError = (payload) => {
  return {
    type: CasherActions.DEPOSIT_ERROR,
    payload: payload,
  };
};
export const getStatusOptions = (payload) => {
  return { type: CasherActions.STATUS_OPTIONS, payload: payload };
};
export const getStatusOptionsSuccess = (payload) => {
  return { type: CasherActions.STATUS_OPTIONS_SUCCESS, payload: payload };
};
export const getStatusOptionsError = (payload) => {
  return { type: CasherActions.STATUS_OPTIONS_ERROR, payload: payload };
};

export const getProductOptions = (payload) => {
  return { type: CasherActions.PRODUCT_OPTIONS, payload: payload };
};
export const getProductOptionsSuccess = (payload) => {
  return { type: CasherActions.PRODUCT_OPTIONS_SUCCESS, payload: payload };
};
export const getProductOptionsError = (payload) => {
  return { type: CasherActions.PRODUCT_OPTIONS_ERROR, payload: payload };
};

export const getTransactionTypes = (payload) => {
  return { type: CasherActions.TRANSACTION_TYPES, payload: payload };
};
export const getTransactionTypesSuccess = (payload) => {
  return { type: CasherActions.TRANSACTION_TYPES_SUCCESS, payload: payload };
};
export const getTransactionTypesError = (payload) => {
  return { type: CasherActions.TRANSACTION_TYPES_ERROR, payload: payload };
};

export const getTransactionHistory = (payload) => {
  return { type: CasherActions.TRANSACTION_HISTORY, payload: payload };
};
export const getTransactionHistorySuccess = (payload) => {
  return { type: CasherActions.TRANSACTION_HISTORY_SUCCESS, payload: payload };
};
export const getTransactionHistoryError = (payload) => {
  return { type: CasherActions.TRANSACTION_HISTORY_ERROR, payload: payload };
};

export const getBetHistory = (payload) => {
  return { type: CasherActions.BET_HISTORY, payload: payload };
};
export const getBetHistorySuccess = (payload) => {
  return { type: CasherActions.BET_HISTORY_SUCCESS, payload: payload };
};
export const getBetHistoryError = (payload) => {
  return { type: CasherActions.BET_HISTORY_ERROR, payload: payload };
};
export const getPaymentHistory = (payload) => {
  return { type: CasherActions.PAYMENT_HISTORY, payload: payload };
};
export const getPaymentHistorySuccess = (payload) => {
  return { type: CasherActions.PAYMENT_HISTORY_SUCCESS, payload: payload };
};
export const getPaymentHistoryError = (payload) => {
  return { type: CasherActions.PAYMENT_HISTORY_ERROR, payload: payload };
};

export const getPaymentType = (payload) => {
  return { type: CasherActions.PAYMENT_TYPE, payload: payload };
};
export const getPaymentTypeSuccess = (payload) => {
  return { type: CasherActions.PAYMENT_TYPE_SUCCESS, payload: payload };
};
export const getPaymentTypeError = (payload) => {
  return { type: CasherActions.PAYMENT_TYPE_ERROR, payload: payload };
};

export const changePassword = (payload) => {
  return { type: CasherActions.CHANGE_PASSWORD, payload: payload };
};
export const changePasswordSuccess = (payload) => {
  return { type: CasherActions.CHANGE_PASSWORD_SUCCESS, payload: payload };
};
export const changePasswordError = (payload) => {
  return { type: CasherActions.CHANGE_PASSWORD_ERROR, payload: payload };
};

export const generateNewTicket = (payload) => {
  return { type: CasherActions.NEW_TICKET, payload: payload };
};

export const getTicketHistory = (payload) => {
  return { type: CasherActions.TICKET_HISTORY, payload: payload };
};
export const getTicketHistorySuccess = (payload) => {
  return { type: CasherActions.TICKET_HISTORY_SUCCESS, payload: payload };
};
export const getTicketHistoryError = (payload) => {
  return { type: CasherActions.TICKET_HISTORY_ERROR, payload: payload };
};

export const depositLoading = (payload) => {
  return {
    type: CasherActions.DEPOSIT_LOADING,
    payload: payload,
  };
};

export const GetClientBalanceForWithdrawSuccess = (payload) => {
  return {
    type: CasherActions.CLIENT_SUCCESS,
    payload: payload,
  };
};

export const GetClientBalanceForWithdrawError = (payload) => {
  return {
    type: CasherActions.CLIENT_ERROR,
    payload: payload,
  };
};