import { UpdateProfileActions } from "../types";

const initialState = {
  updateInfo: [],
  documentType: [],
  documentHistory: [],
  documentStatus: [],
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UpdateProfileActions.UPDATE_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        updateInfo: action.payload,
      };
    }
    case UpdateProfileActions.UPDATE_PROFILE_INFO_ERROR: {
      return {
        ...state,
        updateInfo: action.payload,
      };
    }

    case UpdateProfileActions.DOCUMENT_TYPE_SUCCESS: {
      return {
        ...state,
        documentType: action.payload,
      };
    }
    case UpdateProfileActions.DOCUMENT_TYPE_ERROR: {
      return {
        ...state,
        documentType: action.payload,
      };
    }
    case UpdateProfileActions.DOCUMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        documentHistory: action.payload,
      };
    }
    case UpdateProfileActions.DOCUMENT_HISTORY_ERROR: {
      return {
        ...state,
        documentHistory: action.payload,
      };
    }

    case UpdateProfileActions.DOCUMENT_STATUS_SUCCESS: {
      return {
        ...state,
        documentStatus: action.payload,
      };
    }
    case UpdateProfileActions.DOCUMENT_STATUS_ERROR: {
      return {
        ...state,
        documentStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ProfileReducer;
