import CasherNav from "../context/context";
import { useContext, useEffect, useState } from "react";
import MainHistory from "../History/components/MainHistory";
import BonusesForms from "./BonusesForms/BonusesForms";
import { useDispatch, useSelector } from "react-redux";
import { bonusList, bonuses, getInfo, handlepromocode } from "./Services/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import "./bonuses.scss";
import { useTranslation } from "react-i18next";
import BasicLayout from "../../../RivieraBetBasicLayout";
import CabinetNav from "../components/CabinetNav";
import CabinetNavComponents from "../components/CabinetNavComponents";
import moment from "moment";
import BonusInfoModal from "./BonusInfoModal";

const Bonuses = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bonusUrls } = useContext(CasherNav);
  const bonusStatusList = useSelector(
    (state) => state?.bounsReducer?.bonusStatusList?.ResponseObject
  );
  const bonuslist = useSelector(
    (state) => state?.bounsReducer?.bonuses?.ResponseObject
  );
  const infodata = useSelector(
    (state) => state?.bounsReducer?.Info?.ResponseObject
  );
  const promoCode = useSelector(
    (state) => state?.bounsReducer?.promocode?.ResponseCode
  );
  const [statusSelected, setStatusSelected] = useState("");
  const [bonus, setBonus] = useState(1);
  const [promocode, setPromocode] = useState("");
  const [infomodal, setInfomodal] = useState(false);


  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  const navElBonuses = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Name"),
      dataIndex: "Name",
      key: "Name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "TypeName",
      key: "Type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => (
        <p>
          {text} {CurrencyId}
        </p>
      ),
      responsive: ["sm"],
    },
    {
      title: t("AwardingTime"),
      dataIndex: "AwardingTime",
      key: "Awarding Time",
      render: (text) => <p>{moment(text).format("LLL")}</p>,
      responsive: ["sm"],
    },
    {
      title: t("Final_amount"),
      dataIndex: "FinalAmount",
      key: "Final Amount",
      render: (text) => (
        <p>
          {text} {CurrencyId}
        </p>
      ),
      responsive: ["sm"],
    },
    {
      title: t("Status"),
      dataIndex: "StateName",
      key: "Status",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
    {
      title: t("CalculationTime"),
      dataIndex: "CalculationTime",
      key: "Calculation Time",
      render: (text) => <p>{moment(text).format("LLL")}</p>,
      responsive: ["sm"],
    },
    {
      key: "Info",
      render: (text) => <button className="info-btn" onClick={()=>{
        setInfomodal(true)
        handleInfo(text.BonusId,text.ReuseNumber)}}>Info</button>,
      responsive: ["sm"],
    },
  ];
  const { histUrl, setHistUrl } = useContext(CasherNav);
  const historyNavEl = ["Bonuses"];

  const main = () => {
    if (bonusUrls === "Bonuses")
      return (
        <div className="bonus">
          <div className="cabinet-block">
            <CabinetNavComponents
              isSingleLink={true}
              navEl={historyNavEl}
              url={histUrl}
              setUrl={setHistUrl}
            />

            <BonusesForms
              bonusStatusList={bonusStatusList}
              statusSelected={statusSelected}
              setStatusSelected={setStatusSelected}
              setBonus={setBonus}
              bonus={bonus}
              setPromocode={setPromocode}
              promocode={promocode}
              getBounses={getBounses}
              handlePromocode={handlePromocode}
            />
          </div>
          <div className="cabinet-divider"></div>
          <MainHistory
            empty={t("Bonuses Table is empty")}
            navEl={navElBonuses}
            Payhistory={bonuslist}
          />
        </div>
      );
  };

  const PartnerId = getItemFromLocalStorage("PartnerId");
  const Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const getBounses = () => {
    const payload = {
      Loader: true,
      Controller: "Document",
      Method: "GetBonuses",
      ProductId: bonus,
      Token: Token,
      ClientId: ClientId,
      PartnerId: 43,
      RequestData: JSON.stringify({
        ProductId: bonus,
        Status: statusSelected && statusSelected,
      }),
    };
    dispatch(bonuses(payload));
  };

  const getBonusStatusList = () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 0,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetBonusStatusesEnum",
      Controller: "Main",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    dispatch(bonusList(payload));
  };

  const handlePromocode = async() => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Method: "ActivatePromoCode",
      TimeZone: 0,
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: promocode,
    };
    dispatch(handlepromocode(payload))
    setPromocode("")
  };

  const handleInfo = (bonusid,reuseno) => {
    let payload = {
      Loader: true,
      Controller: "Client",
      Method: "GetClientBonusTriggers",
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({
        BonusId: bonusid,
        ReuseNumber: reuseno,
      }),
    };
    dispatch(getInfo(payload))
  };
  useEffect(() => {
    getBonusStatusList(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBounses(); // eslint-disable-next-line
  }, [bonus]);

  useEffect(() => {
    if (promoCode===0) {
      getBounses();
    }
    // eslint-disable-next-line
  }, [promoCode]);

  return (
    <BasicLayout pageTitle="Bonus">
      <div className="content-px content-py uw-content">
        <CabinetNav />
        {main()}
      </div>
      <BonusInfoModal infomodal={infomodal} setInfomodal={setInfomodal} data={infodata}/>
    </BasicLayout>
  );
};

export default Bonuses;
