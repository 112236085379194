import axios from "axios";

class HomeApi {
  getGames = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

const api = new HomeApi();
export default api;
