import { useEffect } from "react";
import Icon from "../../../Components/global/Icon";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export default function GamesCategories({ categoriesMenu, setGameCategoryId, gameCategoryId, setPageSize, loading }) {
    const { t } = useTranslation();

    useEffect(() => {
        setGameCategoryId(categoriesMenu[0].Type);
    }, [categoriesMenu, setGameCategoryId]);

    return (
        <div className="casino-nav-menu">
            {categoriesMenu.map((x) => (
                <button
                    className={`casino-category ${gameCategoryId === x.Type ? "active" : ""}`}
                    key={x.Id}
                    onClick={() => {
                        if (!loading) {
                            setGameCategoryId(x.Type);
                            setPageSize(24);
                        }
                    }}>
                    <Icon id={_.camelCase(x.Title)} />
                    <p>{t(x.Title === "ClassicSlots" ? "Slots" : x.Title)}</p>
                </button>
            ))}
        </div>
    );
}
