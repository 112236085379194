import { useState } from "react";
import { connect, useSelector } from "react-redux";
import CasinoCardGameModal from "../../../Components/Models/CasinoCardGameModal";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { checkLoginStatus } from "../../Games/actions";
import { getGames } from "../Services/actions";
import { bannerData } from "../data/constantBanerData";
import { useTranslation } from "react-i18next";

import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

export const Banner = (props) => {
  const { t } = useTranslation();
  const { getGames, checkLoginStatus, hideContent } = props;
  const [gamemodel, setgamemodel] = useState(false);
  const [gameName, setgameName] = useState(false);
  const showgameUrl = useSelector((state) => state?.homeReducer?.gameUrl?.data);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const PartnerId = getItemFromLocalStorage("PartnerId") || 43;
  let Token = getItemFromLocalStorage("Token");
  const CliendId = getItemFromLocalStorage("Id");

  const handlegames = async (banner) => {
    if (Token) {
      setgamemodel(true);
      setgameName(banner.title);
      let payload = {
        PartnerId: PartnerId,
        Loader: true,
        TimeZone: 0,
        Controller: "Main",
        LanguageId: LanguageId,
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Email: null,
        Code: null,
        RequestData: "{}",
        IsForDemo: false,
        IsForMobile: isMobile ? true : false,
        Position: "",
        DeviceType: 1,
        Method: "GetProductUrl",
      };
      payload = {
        ...payload,
        Token: Token,
        ClientId: CliendId,
        ProductId: banner.ProductId,
      };
      getGames(payload);
    } else {
      await checkLoginStatus(true);
    }
  };

  SwiperCore.use([Pagination, Autoplay]);

  // useEffect(() => {
  //   if (showgameUrl) {
  //     if (showgameUrl?.ResponseCode === 21) {
  //       setgamemodel(false);
  //       checkLoginStatus(true);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [showgameUrl]);
  return (
    <>
      <div className="banner">
        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          autoplay={{ delay: 4000, pauseOnMouseEnter: !hideContent }}
          loop={true}
          pagination={{ dynamicBullets: true, clickable: true }}
        >
          {bannerData.map((banner, index) => {
            return (
              <SwiperSlide key={`${banner.title}${index}`}>
                <div className="banner-slide">
                  <picture className="banner-slide-img">
                    <source
                      media="(min-width: 1200px)"
                      srcSet={banner.imageDesktop}
                    />
                    <source
                      media="(max-width: 740px)"
                      srcSet={banner.imageMobile}
                    />
                    <img src={banner.imageDesktop} alt={banner.title} />
                  </picture>
                  {!hideContent && (
                    <div className="banner-slide-block">
                      <div className="banner-slide-content">
                        <p className="banner-slide-title">{banner.title}</p>
                        <p className="banner-slide-text">{banner.text}</p>
                        <button
                          onClick={() => handlegames(banner)}
                          className="banner-slide-btn"
                        >
                          {t("Play Now")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <CasinoCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        gameName={gameName}
        showgameUrl={showgameUrl}
      />
    </>
  );
};
export default connect(null, {
  getGames,
  checkLoginStatus,
})(Banner);
