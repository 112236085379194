import { useSelector } from "react-redux";
import BasicLayout from "../../../RivieraBetBasicLayout";
import { aboutUsContent } from "../lib/constants";

const AboutUs = () => {
  const BaseName =
    useSelector((state) => state?.config?.BASE_PRODUCT_NAME) || "";

  return (
    <BasicLayout pageTitle="About us">
      <div className="content-px content-py uw-content legal">
        <div className="layout-title">About us</div>
        <div className="legal-block">
          {aboutUsContent?.map((content, i) => {
            return <p key={i}>{content.replace(/casinoName/g, BaseName)}</p>;
          })}
        </div>
      </div>
    </BasicLayout>
  );
};

export default AboutUs;
