import { Select } from "antd";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { Localisation } from "../../../locale/Localisation";
import { languageDropdown } from "../constantNavBar";

export default function NavbarLang(props) {
  const { Option } = Select;
  const { ChangeLanguage } = Localisation;

  const HandleSelectLanguage = (e) => {
    localStorage.setItem("i18nextLng", e);
    ChangeLanguage(e);
    // localStorage.setItem("key", e.key);
  };

  return (
    <div id={`${props.isTablet ? "navbar-langM-container" : "navbar-lang-container"}`}>
      <Select
        showArrow={false}
        getPopupContainer={() => document.getElementById(`${props.isTablet ? "navbar-langM-container" : "navbar-lang-container"}`)}
        popupClassName="navbar-lang-dropdown"
        className="navbar-lang"
        defaultValue={getItemFromLocalStorage("i18nextLng")}
        onChange={HandleSelectLanguage}>
        {languageDropdown?.map((e, i) => {
          return (
            <Option
              value={e.key}
              key={i}>
              <img
                width="32"
                height="32"
                className="navbar-lang-option"
                src={e.img}
                alt={`${e.value} language icon`}
              />
              {e.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
