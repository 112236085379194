import { Spin } from "antd";
import { useTranslation } from "react-i18next";

export default function PageLoading() {
    const { t } = useTranslation();

    return (
        <div className="game-provider-loader mt-5 d-flex justify-content-center w-100">
            <Spin tip={t("Loading")} data-e2e="games-present" />
        </div>
    )
}
