import browser from "browser-detect";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { saveActivity } from "../../Components/ActivityTracking/activityTrackingService";
import GameCards from "../Casino/Games/GameCards";
import { getDeviceIP } from "../../Components/Common/getIp";
import MobileNavbar from "../../Components/MobileNavbar/MobileNavbar";
import BasicLayout from "../../RivieraBetBasicLayout";
import { replaceGamesKeysNames } from "../../utils/helper";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import Banner from "../Home/components/Banner";
import { games } from "./actions/index";
import "./style.scss";

import { useTranslation } from "react-i18next";
import { Spin } from "antd";

const browserInfo = browser();

const Games = (props) => {
  const { t } = useTranslation();

  const { games, gamesData, loading } = props;
  let allGames = gamesData?.Games;

  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: 100,
      WithWidget: false,
      CategoryId: 5,
      ProviderIds: null,
      IsForMobile: false,
      Pattern: "",
      LanguageId: LanguageId,
    };
    await games(payload);
  };
  useEffect(() => {
    getGamesApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let path = window?.location?.pathname;
    let pageName = path?.split("/")[2] || "";
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetGames",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  }, []);

  return (
    <BasicLayout pageTitle="Games">
      <div className="casino-page games">
        <div className="casino-header">
          <div className="content-px banner-px">
            <MobileNavbar />
            <div className="casino-header-container">
              <Banner hideContent={true} />
            </div>
          </div>
        </div>
        <div className="content-px uw-content">
          <div className="">
            {loading && (
              <div className="game-provider-loader d-flex justify-content-center w-100">
                <Spin
                  tip={t("Loading")}
                  data-e2e="games-present"
                />
              </div>
            )}
            {!loading && allGames && allGames?.length > 0 && (
              <div
                className="casino-games-grid"
                id="infinite-list"
                style={{ overflow: "auto" }}>
                {allGames?.map((game, i) => {
                  let result = replaceGamesKeysNames(game);
                  return (
                    <div key={result.name}>
                      <GameCards
                        isGamesPage={true}
                        data={result}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {!loading && allGames && allGames?.length < 1 && <p className="no-results t-center">{t("No games found")}</p>}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.gamesReducer?.gamesData,
    loading: state?.gamesReducer?.loading,
  };
};

export default connect(mapStateToProps, {
  games,
})(Games);
