import CabinetNavComponents from "../components/CabinetNavComponents";
import CasherNav from "../context/context";
import { useContext, useState } from "react";
import { ticketNavEl } from "../lib/constants";
import MainHistory from "../History/components/MainHistory";
import NewTicket from "../../../Components/Models/Mail/NewTicket";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import BasicLayout from "../../../RivieraBetBasicLayout";
import CabinetNav from "../components/CabinetNav";
const Ticket = () => {
  const { t } = useTranslation();
  const { ticketUrl, setTicketUrl } = useContext(CasherNav);
  const [mailmodel, setmailmodel] = useState(false);

  const TicketHistory = useSelector(
    (state) =>
      state?.cashierReducer?.ticketHistory?.data?.ResponseObject?.Tickets
  );

  const navElTicket = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "Status",
      key: "Status",
      render: (text) => <p>{text === 1 ? t("Active") : t("Closed")}</p>,
    },
    {
      title: t("Subject"),
      dataIndex: "Subject",
      key: "Subject",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Creation Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      render: (text) => <p>{moment(text).format("LL")}</p>,
      responsive: ["sm"],
    },
  ];

  return (
    <>
      <BasicLayout pageTitle="Tickets">
        <div className="content-px content-py uw-content">
          <CabinetNav />
					<div className="cabinet-block">
						<CabinetNavComponents navEl={ticketNavEl} url={ticketUrl} setUrl={setTicketUrl} />
						<div className="bankAccounts-add">
							<button onClick={() => setmailmodel(true)} className="cabinet-btn">
								+ {t("New ticket")}
							</button>
						</div>
					</div>
					<div className="cabinet-divider"></div>
					<MainHistory
            empty={t("Tickets-empty")}
            img="ticket"
            navEl={navElTicket}
            Payhistory={TicketHistory}
          />
					<NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
        </div>
      </BasicLayout>
    </>
  );
};

export default Ticket;
