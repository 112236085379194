export const resources = {
  en: { translation: require("./en/translate.json") },
  da: { translation: require("./da/translate.json") },
  de: { translation: require("./de/translate.json") },
  fr: { translation: require("./fr/translate.json") },
  es: { translation: require("./es/translate.json") },
  it: { translation: require("./it/translate.json") },
  nn: { translation: require("./nn/translate.json") },
  ru: { translation: require("./ru/translate.json") },
  zh: { translation: require("./zh/translate.json") },
  pt: { translation: require("./pt/translate.json") },
  // ptBR: { translation: require('./ptBR/translate.json') },
};
