import { useContext, useState } from "react";
import BasicLayout from "../../../RivieraBetBasicLayout";
import CasherNav from "../context/context";
import CabinetNav from "../components/CabinetNav";
import AccVer from "./AccVer/AccVer";
import MainSection from "./MainSection/MainSection";
import ProfileForm from "./RightSection/ProfileForm";
const Profile = () => {
  const { profileUrl } = useContext(CasherNav);
  const [editStatus, setEditStatus] = useState(false);
  const [settingSelect, setSettingSelect] = useState(true);
  const [navMobile, setNavMobile] = useState(true);

  const handleEditStatus = () => {
    setEditStatus((prev) => !prev);
  };

  const main = () => {
    if (profileUrl === "Settings" || profileUrl === "Responsible Gaming")
      return (
        <div className="profile">
          <MainSection
            editStatus={editStatus}
            handleEditStatus={handleEditStatus}
            settingSelect={settingSelect}
            setSettingSelect={setSettingSelect}
            navMobile={navMobile}
            setNavMobile={setNavMobile}
          />
          <ProfileForm
            editStatus={editStatus}
            handleEditStatus={handleEditStatus}
            settingSelect={settingSelect}
            setSettingSelect={setSettingSelect}
          />
        </div>
      );
    if (profileUrl === "AccountVerification")
      return <div className="profile no-grid">
				<AccVer navMobile={navMobile} setNavMobile={setNavMobile} />
			</div>;
  };

  return (
    <BasicLayout pageTitle="Profile">
      <div className="content-px content-py uw-content">
        <CabinetNav />
				{main()}
      </div>
    </BasicLayout>
  );
};

export default Profile;
