import { Images } from "../../../../Images";
import { frequentlyAskedQuestions } from "../../lib/constants";
import { useState } from "react";
import { useSelector } from "react-redux";

const SupportRight = () => {
  const [displayNone, setDisplayNone] = useState(null);
  const { questionMark, faqOpenIcon, faqOpenIconLight } = Images;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const BaseName =
    useSelector((state) => state?.config?.BASE_PRODUCT_NAME) || "";

  const questions = frequentlyAskedQuestions.map((quest, i) => {
    return (
      <div>
        <div className="support-faq-block" key={quest}>
          <button
            onClick={() => setDisplayNone((prev) => (prev === i ? null : i))}
            className="support-faq-quest"
          >
            <img
              className={displayNone !== i ? "" : "active"}
              loading="lazy"
              width="14"
              height="14"
              src={themeMode === "dark" ? faqOpenIcon : faqOpenIconLight}
              alt="icon"
            />
            <p>{quest.title}</p>
          </button>
          <div
            className={`support-faq-answ ${displayNone !== i ? "" : "active"}`}
          >
            {quest.expl.replace(/casinoName/g, BaseName)}
          </div>
        </div>
        <div className="support-faq-divider"></div>
      </div>
    );
  });

  return (
    <div className="support-faq">
      <div className="support-faq-header">
        <img
          className={themeMode === "light" ? "black-image" : ""}
          width="26"
          height="26"
          loading="lazy"
          src={questionMark}
          alt="question icon"
        />
        <p>Frequently Asked Questions</p>
      </div>
      <div className="support-faq-divider"></div>
      {questions}
    </div>
  );
};

export default SupportRight;
