import { Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../../Images/index";
// import { loginModal } from "../../Login/actions";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import AuthModalImg from "../AuthModalImg";
import { useTranslation } from "react-i18next";
import { registerModal } from "../../Login/actions";
import { useHistory } from "react-router-dom";
import routes from "../../../Route/URLs";

const Register = (props) => {
  const [formsteps, setFormSteps] = useState(1);
  const [registerPayload, setRegisterPayload] = useState({});
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const modalStatus = useSelector(
    (state) => state?.loginReducer?.registerStatusModal
  );


  function handleClose() {
    // const pathname = window.location.search
    // const url = pathname.includes("signup")
    // if (url) {
    // }
    dispatch(registerModal(false));
    history.push(routes.HOME_PAGE);
  }

  function handleClickSteps(step, allowed) {
    if (allowed) {
      setFormSteps(step);
    }
  }

  return (
    <Modal
      open={modalStatus ? modalStatus : props.registerModalStatus}
      footer={null}
      width="880px"
      centered
      className="theme-modal authModal"
      onCancel={handleClose}
    >
      <div className="authModal-row">
        <AuthModalImg />
        <div className="theme-modal-body authModal-body">
          <div className="theme-modal-header authModal-header">
            <p className="theme-modal-heading">{t("Sign Up")}</p>
            <button
              data-e2e="close-icon"
              type="button"
              onClick={handleClose}
              className={`theme-modal-close ${
                themeMode === "light" ? "light-theme" : ""
              }`}
            >
              <img
                width="19"
                height="19"
                loading="lazy"
                src={Images.closeIcon}
                alt="icon"
              />
            </button>
          </div>
          <div className="authModal-steps">
            <button
              onClick={() => handleClickSteps(1, true)}
              className="authModal-steps-btn"
            >
              1
            </button>
            <button
              onClick={() => setFormSteps(2, formsteps >= 2)}
              className={`${
                formsteps >= 2 ? "" : "cursor-not-allowed"
              } authModal-steps-btn`}
            >
              2
            </button>
            <button
              onClick={() => setFormSteps(3, formsteps >= 3)}
              className={`${
                formsteps >= 3 ? "" : "cursor-not-allowed"
              } authModal-steps-btn`}
            >
              3
            </button>
          </div>
          <div className="authModal-reg">
            {formsteps === 1 && (
              <Step1
                registerPayload={registerPayload}
                setRegisterPayload={setRegisterPayload}
                setFormSteps={setFormSteps}
              />
            )}
            {formsteps === 2 && (
              <Step2
                registerPayload={registerPayload}
                setRegisterPayload={setRegisterPayload}
                setFormSteps={setFormSteps}
              />
            )}
            {formsteps === 3 && (
              <Step3
                registerPayload={registerPayload}
                setRegisterPayload={setRegisterPayload}
                setFormSteps={setFormSteps}
                setregisterModal={registerModal}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Register;
