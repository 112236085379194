import { Form, Input, Modal } from "antd";
import { Images } from "../../../../Images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AddAccount = (props) => {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { addAccount, setAddAccount } = props;
  const { t } = useTranslation();


  return (
    <Modal
      open={addAccount}
      footer={null}
      width="700px"
      destroyOnClose
      className="theme-modal addAccountModal"
      onCancel={() => setAddAccount(false)}
      >
			<div className="theme-modal-header mailModal-header">
				<p className="theme-modal-heading">{t("Add_Account")}</p>
				<button
					type="button"
					onClick={() => setAddAccount(false)}
					className={`theme-modal-close ${themeMode === 'light' ? 'light-theme' : ''}`}
				>
					<img
						loading="lazy"
						width="17"
						height="17"
						src={Images.closeIcon}
						alt="close icon"
					/>
				</button>
			</div>
			<div className="theme-modal-body">
				<Form name="login" autoComplete="off">
					<div className="addAccountModal-grid">
						<div className="inputGroup">
							<p className="inputGroup-label">ID</p>
							<Form.Item name="id">
								<Input placeholder="ID" className="theme-input" data-e2e="id-field" />
							</Form.Item>
						</div>
						<div className="inputGroup">
							<p className="inputGroup-label">{t("NickName")}</p>
							<Form.Item name="nick_name">
								<Input placeholder={t("NickName")} className="theme-input" data-e2e="nick-name" />
							</Form.Item>
						</div>
						<div className="inputGroup">
							<p className="inputGroup-label">{t("User_BankName")}</p>
							<Form.Item name="coin">
								<Input placeholder={t("User_BankName")} className="theme-input" data-e2e="coin-text-box" />
							</Form.Item>
						</div>
						<div className="inputGroup">
							<p className="inputGroup-label">{t("Iban")}</p>
							<Form.Item name="token">
								<Input placeholder={t("Iban")} className="theme-input" data-e2e="token-text-field" />
							</Form.Item>
						</div>
						<div className="inputGroup">
							<p className="inputGroup-label">{t("Address")}</p>
							<Form.Item name="address">
								<Input placeholder={t("Address")} className="theme-input" data-e2e="address-text-field" />
							</Form.Item>
						</div>
					</div>
					<Form.Item>
						<button className="addAccountModal-btn theme-btn full-width sqr-btn no-mobile" data-e2e="add-btn">{t("Add_Account")}</button>
					</Form.Item>
				</Form>
			</div>
    </Modal>
  );
};

export default AddAccount;
