import axios from "axios";

class BonusApi {
  bonusStatusList = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetBonusStatusesEnum",
      data
    );
  bonusApi = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/ApiRequest",
      data
    );
    promocodeApi = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/ApiRequest",
      data
    );
}

const api = new BonusApi();
export default api;
