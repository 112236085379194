import { Images } from "../../../Images";
// eslint-disable-next-line no-unused-vars

export const bannerData = [
	{
    title: "Book of Dead",
    text: "",
    imageDesktop: Images.bookOfDeadBannerDesktop,
    imageMobile: Images.bookOfDeadBannerMobile,
    ProductId: 27066,
  },
  {
    title: "Big Bass Bonanza",
    text: "",
    imageDesktop: Images.bigBassBannerDesktop,
    imageMobile: Images.bigBassBannerMobile,
    ProductId: 54029,
  },
	{
    title: "Blinged",
    text: "",
    imageDesktop: Images.blingedBannerDesktop,
    imageMobile: Images.blingedBannerMobile,
    ProductId: 25144,
  },
	{
    title: "Jack Hammer 2",
    text: "",
    imageDesktop: Images.hammerBannerDesktop,
    imageMobile: Images.hammerBannerMobile,
    ProductId: 17022,
  },
	{
    title: "Pimped",
    text: "",
    imageDesktop: Images.pimpedBannerDesktop,
    imageMobile: Images.pimpedBannerMobile,
    ProductId: 27014,
  },
	{
    title: "White King",
    text: "",
    imageDesktop: Images.whiteKingBannerDesktop,
    imageMobile: Images.whiteKingBannerMobile,
    ProductId: 78413,
  },
	// {
  //   title: "Thunder Struck",
  //   text: "",
  //   imageDesktop: Images.thunderBannerDesktop,
  //   imageMobile: Images.thunderBannerMobile,
  //   ProductId: 29805,
  // },
	{
    title: "Wolf Gold",
    text: "",
    imageDesktop: Images.wolfGoldBannerDesktop,
    imageMobile: Images.wolfGoldBannerMobile,
    ProductId: 54060,
  },
  // {
  //   title: "Crazy Time",
  //   text: "",
  //   image: Images.crazyTimeBanner,
  //   ProductId: 21000,
  // },
];
