import axios from "axios";

class GamesApi {
  getGames = (data) =>
    axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetGames", data);
  markGameFavourite = (data) =>
    axios.post(
      " https://websitewebapi.oceanbet.io/43/api/Main/ApiRequest",
      data
    );
  playGame = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

const api = new GamesApi();
export default api;