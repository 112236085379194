import { LoginActions } from "../types";

export const login = (payload) => {
  return {
    type: LoginActions.LOGIN,
    payload: payload,
  };
};

export const loginSuccess = (payload) => {
  return { type: LoginActions.LOGIN_SUCCESS, payload: payload };
};

export const loginError = (payload) => {
  return { type: LoginActions.LOGIN_ERROR, payload: payload };
};

export const signup = (payload) => {
  return {
    type: LoginActions.SIGNUP,
    payload: payload,
  };
};

export const signupSuccess = (payload) => {
  return { type: LoginActions.SIGNUP_SUCCESS, payload: payload };
};

export const signupError = (payload) => {
  return { type: LoginActions.SIGNUP_ERROR, payload: payload };
};

export const getAllCountries = (payload) => {
  return {
    type: LoginActions.GET_COUNTRIES,
    payload: payload,
  };
};

export const getAllCountriesSuccess = (payload) => {
  return { type: LoginActions.GET_COUNTRIES_SUCCESS, payload: payload };
};

export const getAllCountriesError = (payload) => {
  return { type: LoginActions.GET_COUNTRIES_ERROR, payload: payload };
};

export const getAllCities = (payload) => {
  return {
    type: LoginActions.GET_CITIES,
    payload: payload,
  };
};

export const getAllCitiesSuccess = (payload) => {
  return { type: LoginActions.GET_CITIES_SUCCESS, payload: payload };
};

export const getAllCitiesError = (payload) => {
  return { type: LoginActions.GET_CITIES_ERROR, payload: payload };
};

export const getClientByToken = (payload) => {
  return {
    type: LoginActions.GET_CLIENT_BY_TOKEN,
    payload: payload,
  };
};

export const getClientByTokenSuccess = (payload) => {
  return { type: LoginActions.GET_CLIENT_BY_TOKEN_SUCCESS, payload: payload };
};

export const getClientByTokenError = (payload) => {
  return { type: LoginActions.GET_CLIENT_BY_TOKEN_ERROR, payload: payload };
};
export const loginModal = (payload) => {
  return {
    type: LoginActions.LOGIN_MODAL,
    payload: payload,
  };
};

export const loginModalSuccess = (payload) => {
  return { type: LoginActions.LOGIN_MODAL_SUCCESS, payload: payload };
};

export const loginModalError = (payload) => {
  return { type: LoginActions.LOGIN_MODAL_ERROR, payload: payload };
};


export const registerModal = (payload) => {
  return {
    type: LoginActions.REGISTER_MODAL,
    payload: payload,
  };
};

export const registerModalSuccess = (payload) => {
  return { type: LoginActions.REGISTER_MODAL_SUCCESS, payload: payload };
};

export const registerModalError = (payload) => {
  return { type: LoginActions.REGISTER_MODAL_ERROR, payload: payload };
};

export const forgotPassword = (payload) => {
  return {
    type: LoginActions.FORGOT_PASSWORD,
    payload: payload,
  };
};

export const forgotPasswordSuccess = (payload) => {
  return { type: LoginActions.FORGOT_PASSWORD_SUCCESS, payload: payload };
};

export const forgotPasswordError = (payload) => {
  return { type: LoginActions.FORGOT_PASSWORD_FAILURE, payload: payload };
};

export const logout = (payload) => {
  return { type: LoginActions.LOGOUT, payload: payload };
};
