import { GamesActions } from "../types";

export const games = (payload) => {
  return {
    type: GamesActions.GET_GAMES,
    payload: payload,
  };
};

export const checkLoginStatus = (payload) => {
  return {
    type: GamesActions.CHECK_LOGIN_STATUS,
    payload: payload,
  };
};

export const gamesSuccess = (payload) => {
  return { type: GamesActions.GET_GAMES_SUCCESS, payload: payload };
};

export const gamesError = (payload) => {
  return { type: GamesActions.GET_GAMES_ERROR, payload: payload };
};

export const gameMarkFavourite = (payload) => {
  return {
    type: GamesActions.MARK_GAME_FAVOURITE,
    payload: payload,
  };
};

export const gameMarkFavouriteSuccess = (payload) => {
  return { type: GamesActions.MARK_GAME_FAVOURITE_SUCCESS, payload: payload };
};

export const gameMarkFavouriteError = (payload) => {
  return { type: GamesActions.MARK_GAME_FAVOURITE_ERROR, payload: payload };
};

export const playNowGame = (payload) => {
  return {
    type: GamesActions.PLAY_NOW_GAME,
    payload: payload,
  };
};

export const playNowGameSuccess = (payload) => {
  return { type: GamesActions.PLAY_NOW_GAME_SUCCESS, payload: payload };
};

export const playNowGameError = (payload) => {
  return { type: GamesActions.PLAY_NOW_GAME_ERROR, payload: payload };
};

