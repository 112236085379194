export const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    cursor: "pointer",
    height: "40px",
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "var(--input-background)",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "rgba(255, 255, 255, 0.6)" : "var(--input-background)",
    "&:hover": {
      borderColor: "rgba(255, 255, 255, 0.3)",
      boxShadow: state.isFocused ? 0 : 0,
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: "14px",
    cursor: "pointer",
    color: "#222",
    backgroundColor: "#fff !important",
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "#fff",
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    color: "#fff",
  }),
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    color: "#222",
    fontSize: "14px",
  }),
};
