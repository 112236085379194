import { Form, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../../Images/index";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from "../../../utils/localStorage";

import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const dateFormatList = ["DD/MM/YYYY"];

const Step1 = ({ registerPayload, setRegisterPayload, setFormSteps }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [birthDate, setBirthDate] = useState("");
  let step1Data = getItemFromLocalStorage("step1");

  useEffect(() => {
    if (step1Data) {
      setBirthDate(step1Data?.dob);
    }
    //eslint-disable-next-line
  }, []);

  const onFinish = async (values) => {
    let obj = {
      UserName: values.UserName,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      dob: birthDate,
    };
    setRegisterPayload({
      ...registerPayload,
      UserName: values.UserName,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      BirthDay: moment(birthDate).format("DD"),
      BirthYear: moment(birthDate).format("YYYY"),
      BirthMonth: moment(birthDate).format("MM"),
    });
    addItemToLocalStorage("step1", obj);
    setFormSteps(2);
  };

  return (
    <>
      <div className="row">
        <Form
          name="register"
          initialValues={{
            UserName: step1Data?.UserName,
            FirstName: step1Data?.FirstName,
            LastName: step1Data?.LastName,
            Email: step1Data?.Email,
            // dob: step1Data?.dob,
          }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="FirstName"
            rules={[{ required: true, message: t("First name required") }]}
          >
            <Input
              placeholder={t("FirstName")}
              data-e2e="first-name"
              className="theme-input"
              prefix={
                <img
                  width="18"
                  height="18"
                  src={Images.userIconModal}
                  loading="lazy"
                  alt="icon"
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="LastName"
            rules={[{ required: true, message: t("Last name required") }]}
          >
            <Input
              placeholder={t("LastName")}
              data-e2e="last-name"
              className="theme-input"
              prefix={
                <img
                  width="18"
                  height="18"
                  loading="lazy"
                  src={Images.userIconModal}
                  alt="icon"
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="UserName"
            rules={[{ required: true, message: t("Username required") }]}
          >
            <Input
              placeholder={t("UserName")}
              data-e2e="user-name"
              className="theme-input"
              prefix={
                <img
                  width="18"
                  height="18"
                  loading="lazy"
                  src={Images.userIconModal}
                  alt="icon"
                />
              }
            />
          </Form.Item>

          <Form.Item
            name="Email"
            rules={[{ required: true, message: t("Email-placeholder") }]}
          >
            <Input
              placeholder={t("Email")}
              data-e2e="email"
              className="theme-input"
              prefix={
                <img
                  width="18"
                  height="18"
                  loading="lazy"
                  src={Images.emailIcon}
                  alt="icon"
                />
              }
            />
          </Form.Item>
          <Form.Item
            valuePropName="date"
            name="dob"
            rules={[
              { required: true, message: t("Please enter your Date Of Birth") },
            ]}
          >
            <DatePicker
              placeholder={t("Date Of Birth")}
              className="register"
              value={birthDate && moment(birthDate)}
              allowClear={false}
              onChange={(date, dateString) => setBirthDate(date)}
              format={dateFormatList}
            />  
          </Form.Item>
          <button
            data-e2e={t("Next")}
            className="authModal-btn theme-btn full-width sqr-btn no-mobile"
          >
            {t("Next")}
          </button>
        </Form>
      </div>
    </>
  );
};

export default Step1;
